import React, { Component } from "react";
import { connect } from "react-redux";

import "../styles/loader.item.css";

export class LoaderV2 extends Component {
    render() {
        return (
            <div className="loader-container-v2">
                <div className="lds-ripple">
                    <div></div>
                    <div></div>
                </div>
                {this.props.hideText != true ? <span>{this.props.text ? this.props.text : "Loading Content"}</span> : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LoaderV2);
