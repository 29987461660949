import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Tab } from '@headlessui/react';
import clsx from 'clsx';


import { Helmet } from 'react-helmet';
import NewHomeNav from './elements/NewHomeNav';
import Loader, { LoaderV2 } from './tools/Loader';
import server from '../api/server';

import history from '../history';


const DEFAULT_SECTIONS = [{
    "title": "Zero Coding Knowledge Required",
    "description": "BotGhost allows users to set up and customise the bot to their needs without the need of any coding knowledge. Features, such as our command and event builder allows you to simply create commands and events using a drag and drop system.",
    "image": "https://cdn.discordapp.com/attachments/1089691884936503419/1152703487713480725/IMG_49362.png"
}, {
    "title": "A Lifetime Experience",
    "description": "Looking to upgrade to premium? BotGhost offers a ‘Unlimited Lifetime’ subscription option which covers all of your bots premium with a one off payment, no recurring fees!",
    "image": "https://media.discordapp.net/attachments/1089691884936503419/1152720280234496101/Schermafbeelding_2023-09-16_233804.png?width=756&height=662"
}, {
    "title": "Create Multiple Bots",
    "description": "Do you need multiple bots? BotGhost has got you covered. Make as many bots as you need at no extra cost, and they are all hosted 24/7 for free!",
    "image": "https://media.discordapp.net/attachments/1089691884936503419/1152722264421302312/Schermafbeelding_2023-09-16_2343321.png?width=526&height=662"
}, {
    "title": "Do You Need Support",
    "description": "We have a documentation site dedicated to providing in-depth information about all our modules and features! Alternatively, you can also check out our Discord Support Server for any BotGhost-related questions or issues.",
    "image": "https://media.discordapp.net/attachments/1089691884936503419/1152723673220911255/Schermafbeelding_2023-09-16_235132.png?width=1440&height=657"
}, {
    "title": "Game Integrations",
    "description": "Connect various games to your bot using the Game Integration Modules, verify your members with the Roblox Verification module, or choose from a variety of options to suit your needs!",
    "image": "https://media.discordapp.net/attachments/949227937226436648/1153049084509294682/Schermafbeelding_2023-09-16_234332155.png?width=526&height=662"
}, {
    "title": "Social Integrations",
    "description": "Get the latest updates from platforms such as YouTube, Twitch, and Reddit straight through your bot. With the Social Integrations Modules, you can receive updates from your favorite people directly on your server!",
    "image": "https://cdn.discordapp.com/attachments/949227937226436648/1153053984400085052/Schermafbeelding_2023-09-17_214421.png"
}, {
    "title": "Auto-Moderation",
    "description": "Ensure the safety of your server even when no one is around with BotGhosts' extensive Auto-Moderation Modules. Choose between integrating the Discord Auto-Moderation feature into your bot or using BotGhosts' built-in Auto-Moderation module. Your server will stand out like no other!",
    "image": "https://media.discordapp.net/attachments/949227937226436648/1153048179256860702/Schermafbeelding_2023-09-17_212043.png?width=1440&height=550"
}, {
    "title": "Custom Variables and Data Storage",
    "description": "BotGhost has a built-in data storage system that anyone can use to keep track of warnings given to users or save other important data to custom data variables. You can call each variable in different commands to reveal the data values inside and display that important information anywhere in your server.",
    "image": "https://media.discordapp.net/attachments/949227937226436648/1153050316376387635/Schermafbeelding_2023-09-17_212929.png?width=1220&height=662"
}, {
    "title": "Auto-Responder",
    "description": "With BotGhosts' Auto-Responder Module, you can automate responses to keywords, remind people where to go for support, and send a greeting back to a user. Let your imagination run wild with the Auto-Responder Module; your imagination is the only limitation!",
    "image": "https://cdn.discordapp.com/attachments/949227937226436648/1153052124226269265/Schermafbeelding_2023-09-16_2343321552.png"
}, {
    "title": "Image Generation and ChatGPT",
    "description": "Get started with one of our highly intelligent AI modules and begin using ChatGPT in your server, generate images from words, and collaborate with an AI to write exciting novels. Have a counting battle with an AI; there really is nothing you can't do!",
    "image": "https://cdn.discordapp.com/attachments/949227937226436648/1153052677517885480/Schermafbeelding_2023-09-17_2137451.png"
}, {
    "title": "Moderate with Ease",
    "description": "Create and deploy advanced moderation tools, or activate the built-in Moderation Module to access a wide range of highly advanced moderation commands, making your server moderation a breeze. Change permissions, edit triggers, and enjoy complete customization at your fingertips.",
    "image": "https://cdn.discordapp.com/attachments/949227937226436648/1153051669412716725/Schermafbeelding_2023-09-16_2343321551.png"
}];
const DEFAULT_FAQS = [
    {
        "question": "Is my bot 24/7 active with BotGhost?",
        "answer": "Yes. All BotGhost bots remain active 24/7 as long as there is at least one interaction every 7 days. Premium users, however, are exempt from this requirement."
    }, {
        "question": " Where can I contact customer service?",
        "answer": "You can reach out to us through the BotGhost Support Server if you are experiencing issues with your bot, subscription, or have any other inquiries or questions."
    }, {
        "question": "How can I change my prefix?",
        "answer": "Text/prefix commands have been removed from BotGhost due to Discord's message intent enforcement. Only slash commands can be created using BotGhost. Prefix commands like ! and ? can no longer be generated with BotGhost. You can learn more about message intent enforcement here: https://btghst.info/prefix. From now on, you can only use / (slash) commands!"
    }, {
        "question": "What are Beta Features?",
        "answer": "Beta Features are predefined features that have not yet been released to the public. They may still be under development and in the testing phase. BotGhost Premium users can access beta features by enabling them in the bot's settings. This will grant you access to unreleased features."
    }, {
        "question": "Can I use the bot in more than one server?",
        "answer": "Yes, although BotGhost is primarily designed for single-server use, you can use a single bot in multiple different servers."
    }, {
        "question": "Is there a way to get free premium?",
        "answer": "Yes, BotGhost offers a 3-day single-use premium trial for joining our Discord support server. You can find out more on the 'Free Premium' page on the dashboard."
    }, {
        "question": "How many modules are available for free on BotGhost?",
        "answer": "Currently, there are 30 modules available for you to use for free, with occasional updates and new releases."
    }, {
        "question": "Is BotGhost safe to use?",
        "answer": "Absolutely, the use of BotGhost is very secure! You can see how we handle the security of your bot and your personal data by using the following two links: https://botghost.com/security and https://botghost.com/privacy-policy."
    }
];


export class IFTTTtemplate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            title: "",
            description: "",
            slug: "roblox",
            category: "Roblox",
            features_description: "FEATURES DESCRIPTION",
            icon: "https://assets.ifttt.com/images/channels/2/icons/monochrome_large.png",
            features: [

            ],
            faqs: [

            ],
            sections: [
            ]
        };
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.slug != this.props.match.params.slug) {
            // this.getData();
        }
    }

    getData = async () => {
        var response = await server.get(`/home/ifttt/${this.props.match.params.slug}`);
        if (response.data) {
            var service = response.data;
            var faqs = [];
            // Split faqs into 3 columns
            var moduleFaqs = [...DEFAULT_FAQS];
            for (var i = 0; i < moduleFaqs.length; i += 3) {
                faqs.push(moduleFaqs.slice(i, i + 3));
            }
            this.setState({
                loaded: true,
                icon: service.icon,
                color: service.color,
                category: service.name,
                slug: service.slug,
                // sections: module.sections,
                // features_description: module.features_description,
                // sections_description: module.sections_description,
                // faqs_description: module.faqs_description,
                // icon: module.icon,
                // live: module.live,

            });
        } else {
            window.location.replace("/");
        }
    };

    renderSections = () => {
        var sections = [];

        this.state.sections.forEach((section, index) => {
            sections.push(
                <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-[150px] lg:mb-[150px]">
                    <div className={`flex flex-col text-center mt-5 lg:!text-left px-2 order-last lg:mr-48 ${index % 2 == 0 ? "lg:!order-first" : null}`} style={{ justifyContent: "center" }}>

                        <h2 className="text-white font-bold text-[35px] mb-2">{section.title}</h2>
                        <p className="opacity-70 text-white text-[17px]">{section.description}</p>

                    </div>

                    <div>

                        <img src={section.image}></img>

                    </div>
                </div>
            );
        });

        DEFAULT_SECTIONS.forEach((section, index) => {
            sections.push(
                <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-[150px] lg:mb-[150px]">
                    <div className={`flex flex-col text-center mt-5 lg:!text-left px-2 order-last lg:mr-48 ${index % 2 == 0 ? "lg:!order-first" : null}`} style={{ justifyContent: "center" }}>

                        <h2 className="text-white font-bold text-[35px] mb-2">{section.title}</h2>
                        <p className="opacity-70 text-white text-[17px]">{section.description}</p>

                    </div>

                    <div>

                        <img src={section.image}></img>

                    </div>
                </div>
            );
        });
        return sections;
    };
    render() {
        if (this.state.loaded == false) {
            return <div className='h-[100vh] w-[100%] flex justify-center bg-darkGray'>
                <LoaderV2 hideText={true}></LoaderV2>
            </div>;
        }
        return (
            <>
                <Helmet>
                    <title>Create your own {this.state.category} Discord Bot | Free Discord Bot Maker</title>
                    <meta name="description" content={`Create your own ${this.state.category} Discord Bot with no coding required. Use IFTTT and BotGhost to effortlessly create your own {this.state.category} Discord Bot.`} />
                    {this.state.live == false ? <meta name="robots" content="noindex"></meta> : null}
                </Helmet>
                <NewHomeNav borderBottom={false}></NewHomeNav>

                <header className='bg-darkGray px-5 py-[120px] lg:py-[180px] lg:!px-[25%]'>

                    <div className='w-full flex justify-center'>
                        <div className="text-center">
                            <div className="flex justify-center">
                                <img src={this.state.icon} className="h-[90px] w-[90px]" ></img>
                            </div>
                            <h1 className=" text-[50px] lg-text-[70px] mb-3 font-bold">Create your own <span className='text-red'>{this.state.category} Discord</span> Bot</h1>
                            <h3 className="opacity-70 text-white text-[20px]">Create your own {this.state.category} Discord Bot with no coding required. Use IFTTT and BotGhost to effortlessly create your own {this.state.category} Discord Bot.</h3>
                            <a className="btn btn-red font-bold mt-3 mr-3 px-4 py-2 text-[20px]" href="https://dashboard.botghost.com/">Create Your {this.state.category} Bot</a>
                            <a className="btn btn-red !bg-[#09f] font-bold mt-3 px-4 py-2 text-[20px]" target="_blank" href={`https://ifttt.com/connect/botghost/${this.state.slug}`}>View on IFTTT</a>
                        </div>
                    </div>

                </header>

                <div
                    id="features"
                    aria-label="Features for running your books"
                    className="relative overflow-hidden bg-red pt-20 pb-28"
                >
                    <img
                        className="absolute left-1/2 top-1/2 max-w-none translate-x-[-44%] translate-y-[-42%]"
                        src={process.env.PUBLIC_URL + "/img/redgradient.png"}
                        alt=""
                        width={2245}
                        height={1636}
                        unoptimized
                    />
                    <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
                            <h2 className="font-display text-3xl font-bold tracking-tight text-white sm:text-4xl md:text-5xl">
                                Everything you need to create your own {this.state.category} Discord Bot.
                            </h2>
                            <p className="mt-6 text-lg tracking-tight text-white">
                                Create your bot and connect it to IFTTT to create your own {this.state.category} Discord Bot. Use IFTTT to connect your bot to Twitter, Reddit, and more.
                            </p>
                        </div>
                        {/* <Tab.Group
                            as="div"
                            className="mt-16 grid grid-cols-1 items-center gap-y-5 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0"
                            vertical={this.state.tabOrientation === 'vertical'}
                        >
                            {({ selectedIndex }) => (
                                <>
                                    <div className="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
                                        <Tab.List className="relative z-10 flex gap-y-6 whitespace-nowrap  sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:whitespace-normal">
                                            {this.state.features.map((feature, featureIndex) => (
                                                <div
                                                    key={feature.title}
                                                    className={clsx(
                                                        'group relative rounded-full px-4 py-4 lg:rounded-l-xl lg:rounded-r-none lg:p-6',
                                                        selectedIndex === featureIndex
                                                            ? 'bg-[#ef6d61] lg:bg-white/10 '
                                                            : 'hover:bg-white/10 lg:hover:bg-white/5',
                                                    )}
                                                >
                                                    <h3>
                                                        <Tab
                                                            className={clsx(
                                                                'font-semibold text-2xl ui-not-focus-visible:outline-none',
                                                                selectedIndex === featureIndex
                                                                    ? 'text-white lg:text-white'
                                                                    : 'text-white hover:text-white lg:text-white',
                                                            )}
                                                        >
                                                            <span className="absolute inset-0 rounded-full font-bold lg:rounded-l-xl lg:rounded-r-none" />
                                                            {feature.title}
                                                        </Tab>
                                                    </h3>
                                                    <p
                                                        className={clsx(
                                                            'mt-2 hidden text-md lg:block opacity-70',
                                                            selectedIndex === featureIndex
                                                                ? 'text-white'
                                                                : 'text-white group-hover:text-white',
                                                        )}
                                                    >
                                                        {feature.description}
                                                    </p>
                                                </div>
                                            ))}
                                        </Tab.List>
                                    </div>
                                    <Tab.Panels className="lg:col-span-7">
                                        {this.state.features.map((feature) => (
                                            <Tab.Panel key={feature.title} unmount={false}>
                                                <div className="relative sm:px-6 lg:hidden">
                                                    <div className="absolute -inset-x-4 bottom-[-4.25rem] top-[-6.5rem] bg-white/10  sm:inset-x-0 sm:rounded-t-xl" />
                                                    <p className="relative mx-auto max-w-2xl text-base text-white sm:text-center">
                                                        {feature.description}
                                                    </p>
                                                </div>
                                                <div className="mt-10 w-[45rem] overflow-hidden rounded-xl bg-slate-50 shadow-xl  sm:w-auto lg:mt-0 lg:w-[67.8125rem]">
                                                    <img
                                                        className="w-full"
                                                        src={feature.image}
                                                        alt=""
                                                        priority
                                                        sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
                                                    />
                                                </div>
                                            </Tab.Panel>
                                        ))}
                                    </Tab.Panels>
                                </>
                            )}
                        </Tab.Group> */}
                    </div>
                </div>

                {/* 
                <div className="bg-red">
                    <div className="container py-32 flex justify-center">
                        <div className='text-center text-white'>
                            <h2 className='font-bold text-[48px] '>Everything you need to create your own Roblox Bot</h2>
                            <p className='mt-6 text-lg opacity-90'>It’s time to take control of your books. Buy our software so you can feel like you’re doing something productive.</p>

                        </div>
                    </div>

                </div> */}


                <section className=" bg-darkGray py-5 pt-14 mt-0">

                    <div className='container mt-24 mb-24 text-white text-center'>
                        <h2 className='font-display text-3xl font-bold tracking-tight text-white sm:text-4xl md:text-5xl'>0 Coding Knowledge Required</h2>
                        <p className='mt-6 text-lg tracking-tight text-white opacity-70'>{this.state.sections_description}</p>
                    </div>
                    <div className="container lg:pt-24">
                        {this.renderSections()}
                    </div>
                </section>


                <section className='bg-red py-24 mt-0'>
                    <div className='container flex flex-col justify-center text-center'>
                        <h2 className='font-display text-3xl font-bold tracking-tight text-white sm:text-4xl md:text-5xl'>Get Started Today</h2>
                        <p className='mt-6 text-lg tracking-tight text-white'> Start creating your very own {this.state.category} Discord Bot.</p>
                        <a className='mt-6 btn btn-red bg-white text-darkGray font-bold rounded-full border-red px-4 py-2 text-[20px]' href="https://dashboard.botghost.com/">Create Your {this.state.category} Discord Bot</a>
                    </div>
                </section>


                <section
                    id="faq"
                    aria-labelledby="faq-title"
                    className="relative bg-darkGray py-5 pt-14 mt-0 overflow-hidden bg-slate-50 py-20 sm:py-32"
                >
                    <div
                        className="absolute bg-red left-1/2 top-0 max-w-none -translate-y-1/4 translate-x-[-30%]"
                        alt=""
                        width={1558}
                        height={946}
                        unoptimized
                    />
                    <div className="relative">
                        <div className="mx-3 lg:!mx-64 mb-24 mt-24">
                            <div>
                                <h2 className='font-display text-3xl font-bold tracking-tight text-white sm:text-4xl md:text-5xl'>Frequently asked questions</h2>
                                <p className='mt-6 text-lg tracking-tight text-white opacity-70'>{this.state.faqs_description}</p>

                            </div>
                        </div>

                        <div className=' mx-3 lg:!mx-64'>
                            <ul
                                role="list"
                                className="mx-auto container mt-16 text-white grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3"
                            >
                                {this.state.faqs.map((column, columnIndex) => (
                                    <li key={columnIndex}>
                                        <ul role="list" className="flex flex-col gap-y-8">
                                            {column.map((faq, faqIndex) => (
                                                <li key={faqIndex}>
                                                    <h3 className="font-display font-bold text-2xl leading-7 text-slate-900">
                                                        {faq.question}
                                                    </h3>
                                                    <p className="mt-4 text-md opacity-70">{faq.answer}</p>
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </section>

                <footer className='pt-24 pb-24 bg-darkGray'>

                    <div className='flex justify-center flex-col'>
                        <img className='h-[60px]' src={process.env.PUBLIC_URL + '/img/logo-red.png'}></img>

                        {/* <div class="-my-1 flex justify-center gap-x-6 mt-2">
                            <a class="inline-block rounded-lg px-2 py-1 text-sm text-white hover:bg-slate-100 hover:text-slate-900" href="#features">Privacy Policy</a>
                            <a class="inline-block rounded-lg px-2 py-1 text-sm text-white hover:bg-slate-100 hover:text-slate-900" href="#testimonials">Security</a>
                            <a class="inline-block rounded-lg px-2 py-1 text-sm text-white hover:bg-slate-100 hover:text-slate-900" href="#pricing">Terms of Service</a>
                        </div> */}
                    </div>

                </footer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default IFTTTtemplate;