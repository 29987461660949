import React, { Component } from 'react'
import { connect } from 'react-redux'

import {faBars, faDownload, faServer, faUser, faUsers} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export class MarketItem extends Component {
    render() {
        return (
            <div className="market-grid-item-container">
                {/* <div className="market-grid-item">
                    <div className="market-grid-image">
                        <img src="https://images.discordapp.net/avatars/432610292342587392/29cb28fbf65a3958105026ab03abd306.png?size=128"></img>
                    </div>

                    <div className="market-grid-info">
                        <div style={{width:"60%"}} className="market-grid-details">
                            <div style={{lineHeight:"1.2"}}><span className="author">scuffedshrek</span></div>
                            <div style={{lineHeight:"1.2"}}><span className="name-text">BotGhost Bot</span></div>
                        </div>

                        <div style={{width:"40%"}} className="market-grid-stats">
                            <div style={{lineHeight:"1.2"}}><span className="author"><FontAwesomeIcon color={"#ee4440"} icon={faUsers}/> 223</span></div>
                            <div style={{lineHeight:"1.2"}}><span className="author"><FontAwesomeIcon color={"#ee4440"} icon={faDownload}/> 2423 </span></div>
                        </div>
                    </div>
                </div> */}
                <div class=" module">
                    <a href={`/marketplace/bot/${this.props.id}`}>
                        <div class="module-inner">
                            <div class="module-left">
                                <img class="module-icon" src={this.props.img || process.env.PUBLIC_URL + "/img/logo-new.png"}/>
                            </div>
                            <div class="module-right">
                                <div class="module-name">{this.props.name}</div>
                                <div class="module-description">A custom Discord bot made with BotGhost.</div>
                            </div>
                            <div class="module-bottom" style={{textAlign:"right"}}>
                                <span className="market-item-stats"><FontAwesomeIcon icon={faUsers}/> {this.props.stats.servers || 0}</span>
                                {/* <span className="market-item-stats"> <FontAwesomeIcon icon={faUsers}/> 1138 </span> */}
                            </div>
                        </div>
                    </a>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketItem)
