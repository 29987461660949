import React, { Component } from 'react';
import ToolContainer from './ToolContainer';
import ToolInput from './components/ToolInput';

const FAQS = [
    {
        question: "What is hexadecimal?",
        answer: "Hexadecimal is a base-16 number system that uses 16 distinct symbols: the numbers 0-9 and the letters A-F."
    },
    {
        question: "How do I convert hex to decimal?",
        answer: "You can use this tool to easily convert hexadecimal numbers to decimal. Simply enter the hex value and the tool will show you the decimal equivalent."
    },
    {
        question: "Why would I need to convert hex to decimal?",
        answer: "Hex to decimal conversion is often needed in programming, especially when dealing with color codes, memory addresses, or certain types of data representation."
    },
    {
        question: "How is hexadecimal used in Discord?",
        answer: "In Discord, hexadecimal is commonly used for color codes in embeds, role colors, and other UI elements. For example, #7289DA is Discord's brand color in hex."
    },
    {
        question: "Can I use this tool for Discord color codes?",
        answer: "Yes! This tool is perfect for converting Discord color codes from hex to decimal, which can be useful when working with Discord's API or bot development."
    },
    {
        question: "What's the relationship between hex colors and Discord role colors?",
        answer: "Discord uses hexadecimal color codes for role colors. You can use this tool to convert those hex codes to decimal if needed for certain Discord bot commands or API calls."
    },
    {
        question: "How do I find the hex code for a Discord role color?",
        answer: "In Discord, go to Server Settings > Roles, select a role, and you'll see the hex color code. You can then use this tool to convert it to decimal."
    },
    {
        question: "Why might I need to convert Discord color codes to decimal?",
        answer: "Some Discord bot libraries or API endpoints require color values in decimal format. This tool helps you convert from the hex format you see in Discord to the decimal format you might need for coding."
    },
    {
        question: "Are Discord IDs related to hexadecimal?",
        answer: "While Discord IDs are not in hexadecimal format, they are large numbers that are sometimes easier to work with in different number bases. This tool can help convert between bases if needed."
    },
    {
        question: "How does hexadecimal relate to Discord's branding?",
        answer: "Discord's primary brand color is #7289DA in hexadecimal. You can use this tool to convert it to decimal or binary to see its numeric representation."
    },
    {
        question: "Can this tool help with Discord bot development?",
        answer: "Yes! When developing Discord bots, you might encounter hex values for colors or other data. This tool can help you convert those values as needed for your bot's code."
    },
    {
        question: "How accurate is this hex to decimal converter?",
        answer: "This converter provides precise conversions for hexadecimal numbers up to 15 digits long, which covers all practical uses including Discord color codes."
    },
    {
        question: "Can I convert Discord timestamp colors with this tool?",
        answer: "Absolutely! Discord uses hex color codes for customizing timestamp colors in embeds. You can convert these hex codes to decimal using this tool."
    },
    {
        question: "How do hexadecimal colors work in Discord embeds?",
        answer: "Discord embeds use hexadecimal color codes to set the color of the embed's left border. You can use this tool to convert those hex codes if you need the decimal equivalent."
    },
    {
        question: "Is hexadecimal used in Discord's API?",
        answer: "While Discord's API generally uses decimal for color values, many Discord libraries and user interfaces display colors in hex. This tool helps bridge that gap."
    },
    // Add more FAQs as needed
];

export class HexToDecimal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hex: '',
            decimal: '',
            binary: '',
            error: null
        };
    }

    convertHex = () => {
        const hex = this.state.hex.replace(/^0x/, '');  // Remove '0x' if present
        if (!/^[0-9A-Fa-f]+$/.test(hex)) {
            this.setState({ error: 'Invalid hexadecimal number' });
            return;
        }

        const decimal = parseInt(hex, 16);
        const binary = decimal.toString(2);

        this.setState({
            decimal: decimal.toString(),
            binary: binary,
            error: null
        });
    };

    render() {
        return (
            <ToolContainer faqs={FAQS} title="Hexadecimal to Decimal Converter" description="Convert hexadecimal numbers to decimal and binary">
                <div className='lg:px-[25%]'>
                    <div className="text-center text-white flex justify-center flex-col mb-3">
                        <img className='h-[60px]' src={process.env.PUBLIC_URL + '/img/logo-red.png'} alt="Logo" />
                        <h1 className='text-5xl font-bold'>Hexadecimal to Decimal Converter</h1>
                        <p className='muted-text'>Convert hexadecimal numbers to decimal and binary representations.</p>
                    </div>

                    <div className='mt-5'>
                        <ToolInput
                            label="Hexadecimal Input"
                            value={this.state.hex}
                            onChange={(value) => this.setState({ hex: value })}
                            placeholder="Enter hexadecimal number (e.g., 1A or 0x1A)"
                        />
                        <button className='btn btn-red mt-2' onClick={this.convertHex}>
                            Convert
                        </button>
                    </div>

                    {this.state.error && (
                        <div className='text-red mt-5 font-bold text-xl'>
                            {this.state.error}
                        </div>
                    )}

                    {this.state.decimal && (
                        <div className='mt-5'>
                            <h2 className='text-2xl font-bold text-white mb-3'>Result</h2>
                            <div className='bg-[#2f3136] px-5 py-5 mt-3 rounded-md'>
                                <p className='text-white text-lg'>
                                    <span className='font-bold text-[#43b581]'>Decimal:</span> {this.state.decimal}
                                </p>
                                <p className='text-white text-lg mt-2'>
                                    <span className='font-bold text-[#43b581]'>Binary:</span> {this.state.binary}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </ToolContainer>
        );
    }
}

export default HexToDecimal;
