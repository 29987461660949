import React, { Component } from 'react'
import { connect } from 'react-redux'

export class NewModuleDefault extends Component {
    render() {
        return (
            <div className="home-info-section home-info-ordering">



            <section>
                <div className="home-info-section-inner">
                    <div className="home-info-part">
                        <div>
                            <h2>24/7  Bot Hosting</h2>
                            <p>Ensure your bot stays online with 24/7 free hosting. No downloaded program or app required.</p>
                        </div>    
                    </div>

                    <div className="home-info-part">
                        <div>
                            <img style={{maxHeight:"500px",width:"60%"}} src={process.env.PUBLIC_URL + "/img/home_images/online.PNG"}></img>
                        </div>

                    </div>
                </div>
            </section>

            <section>
                <div className="home-info-section-inner reverse-order">

                <div className="home-info-part">
                        <div>
                            <img src={process.env.PUBLIC_URL + "/img/home_images/multibot.PNG"}></img>
                        </div>

                    </div>


                    <div className="home-info-part">
                        <div>
                            <h2>Deploy Multiple Bots</h2>
                            <p>
                                Deploy and manage as many Discord Bots as you please. Create a bot for any occasion.
                            </p>
                        </div>    
                    </div>
                </div>
            </section>

            <section>
                <div className="home-info-section-inner">
                    <div className="home-info-part">
                        <div>
                            <h2>Fully Customizable</h2>
                            <p>Easily configure your bot on the go with absolutely no coding required. Enable and disable modules to tailor your discord bot to your servers needs. Easily get started by pasting your bots token into your dashboard, saving and going online.</p>
                        </div>    
                    </div>

                    <div className="home-info-part">
                        <div>
                            <img src={process.env.PUBLIC_URL + "/img/home_images/commands.PNG"}></img>
                        </div>

                    </div>
                </div>
            </section>

            <section>
                <div className="home-info-section-inner reverse-order">

                <div className="home-info-part">
                        <div>
                            <img src={process.env.PUBLIC_URL + "/img/home_images/custom commands.PNG"}></img>
                        </div>

                    </div>


                    <div className="home-info-part">
                        <div>
                            <h2>Design your own commands</h2>
                            <p>Setup your own text, embed and random commands. Give server info, news and more with BotGhost's custom discord commands. Simply input your custom tag, and create a response. Select from variables to make your bot feel more human and personable.</p>
                        </div>    
                    </div>
                </div>
            </section>

            <section>
                <div className="home-info-section-inner">
                    <div className="home-info-part">
                        <div>
                            <h2>Send Announcements to the server</h2>
                            <p>Make new members feel welcome with announcements messages when they join. Create announcements for when users leave or are banned, to notify the rest of the server.</p>
                        </div>    
                    </div>

                    <div className="home-info-part">
                        <div>
                            <img src={process.env.PUBLIC_URL + "/img/home_images/announcement.PNG"}></img>
                        </div>

                    </div>
                </div>
            </section>

            <section>
                <div className="home-info-section-inner reverse-order">

                <div className="home-info-part">
                        <div>
                            <img src={process.env.PUBLIC_URL + "/img/home_images/modules.PNG"}></img>
                        </div>

                    </div>


                    <div className="home-info-part">
                        <div>
                            <h2>Explore 20+ Modules</h2>
                            <p>Moderate your Discord Server, Integrate with Social Media, Play games and level up or access a range of utilities. </p>
                        </div>    
                    </div>
                </div>
            </section>



        </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(NewModuleDefault)
