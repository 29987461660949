import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Categories } from './Categories'
import { MarketItem } from './MarketItem'
import { Title } from './Title'

import server from '../../api/server'
import CommandItem from './CommandItem'

export class Main extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             commands:[
                 {
                     id:"1234",
                    name:"ticket",
                    img:"https://preview.redd.it/ht4eu3k0zgr31.png?width=640&crop=smart&auto=webp&s=31c53752000d75c9c5154c26d8b61d12b1d85ff8",

                    description:"This command will give your bot a custom status with anything you want! Just hit that run button and your status wil change!",
                    imports:12532,
                    views:2532,
                    tags:["economy","fun",'moderation','tickets']
                 }
             ]
        }
    }
    
    componentDidMount() {
        this.getBots()
    }


    getBots = async () =>{
        var data = await server.get("/bots");
        console.log(data)
        this.setState({bots:data.data})
    }

    renderCommands = () =>{
        var commands = []
        this.state.commands.forEach(command =>{
            commands.push(
                <CommandItem command={command}/>
            )
        })

        return commands;
    }
    render() {
        return (
            <div style={{width:"100%",paddingTop:"15px"}} className="">
                <Title header="Popular Commands" description="Find the best Discord Bot for your server. All created with BotGhost."/>
                <div className="market-grid">
                    {/* <CommandItem/>
                    <CommandItem/>
                    <CommandItem/>
                    <CommandItem/>
                    <CommandItem/>
                    <CommandItem/> */}
                    {this.renderCommands()}
                    {this.renderCommands()}
                    {this.renderCommands()}
                    {this.renderCommands()}
                    {this.renderCommands()}
                    {this.renderCommands()}

                </div>
                
                <Title header="Explore by Category" description="Browse Discord Bots by Category."/>
                <Categories/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)
