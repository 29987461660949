import React, { Component } from 'react'
import { connect } from 'react-redux'
import server from '../api/server'

import ReactGA from 'react-ga';
import {Helmet} from "react-helmet"
// import 'bootstrap/dist/css/bootstrap.min.css';
export class Home extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
       active:0,
       members:0,
       servers:0
    }
  }
  

  componentDidMount() {
    ReactGA.pageview(window.location.pathname)
      this.setStats()

  }

  setStats = async () =>{
    const response = await server.get("/homestats");
    if(response){
      this.setState({
        active:response.data.bots,
        members:response.data.members,
        servers:response.data.servers
      })
    }
  }

    render() {
        return (
            <html>
            <Helmet>
              <link rel="stylesheet" media="print" onload="this.onload=null;this.removeAttribute('media');" rel="stylesheet" href="https://use.fontawesome.com/releases/v5.6.3/css/all.css" integrity="sha384-UHRtZLI+pbxtHCWp1t77Bi1L4ZtiqrqD80Kn4Z8NTSRyMA2Fd33n5dQ8lWUE00s/" crossorigin="anonymous"/>
              {/* <link rel="stylesheet" type="text/css" href="/css/bootstrap.min.css"/> */}
              <link rel="stylesheet" type="text/css" href="/css/home.css"/>
              <link rel="stylesheet" media="print" onload="this.onload=null;this.removeAttribute('media');"  href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,700&display=swap"/>
                  <meta name="title" content="Discord Bot Maker and Hosting | BotGhost"/>
              <title>Discord Bot Maker and Hosting | BotGhost</title>
            <meta name="description" content="Make your own discord bot for free in 5 minutes with no coding required. Host your own discord bot for moderation, music, twitch.tv, fortnite and more."/>
            <meta name="keywords" content="discord bot, discord bot hosting, how to make a discord bot, discord bot maker, discord moderation bot, discord music bot, discord twitch bot"/>
            <meta name="twitter:card" content="summary"/>
            <meta name="twitter:title" content="Create a Discord Bot with no coding required"/>
            <meta name="twitter:description" content="Create your own discord bot in 5 minutes with no coding required. Use our discord bot maker to create a bot for moderation, music, twitch.tv, fortnite and more."/>
            <meta name="twitter:image" content="http://www.botghost.com/img/logo-new.png"/>
            
            <meta property="og:title" content="Discord Bot Maker and Hosting | BotGhost"/>
            <meta property="og:site_name" content="BotGhost"/>
            <meta property="og:url" content="https://botghost.com/"/>
            <meta property="og:description" content="Make your own discord bot for free in 5 minutes with no coding required. Host your own discord bot for moderation, music, twitch.tv, fortnite and more."/>
            <meta property="og:type" content="website"/>
            <meta property="og:image" content="https://botghost.com/img/home_main.PNG"/>
            <meta property="og:image" content="https://botghost.com/img/home_custom.PNG"/>
            <meta property="og:image" content="https://botghost.com/img/home_custom.PNG"/>
            
            <meta name="viewport" content="width=device-width, initial-scale=1"/>
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
            <meta name="robots" content="index, follow"/>
            <meta name="language" content="English"/>
            <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1"/>
            <meta name="HandheldFriendly" content="true"/>
            
            <link rel="shortcut icon" href="%PUBLIC_URL%/img/favicon.ico" type="image/x-icon"/>
            <link rel="icon" href="%PUBLIC_URL%/img/favicon.ico" type="image/x-icon"/>
            
            
            </Helmet>
            <body>
              <div className="home-blocker"></div>
              <header>
                <nav class="navbar navbar-expand-lg">
                  <a class="navbar-brand" href="/">
                    BotGhost
                  </a>
                  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"><i class="fas fa-bars"></i></span>
                  </button>
                  <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav mr-auto">
                      <li class="nav-item">
                        <a class="nav-link" href="#features">Features</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="/features">Modules</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="https://docs.botghost.com" target="_blank">Documentation</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="/bots">Bots</a>
                      </li>

                      <li class="nav-item">
                        <a class="nav-link" href="/blog">Blog</a>
                      </li>
            
                      <li class="nav-item">
                        <a class="nav-link" href="https://twitter.com/GetBotGhost" target="_blank">Update</a>
                      </li>
            
                      
                      <li class="nav-item">
                        <a class="nav-link" href="https://dashboard.botghost.com/dashboard">Dashboard</a>
                      </li>
                    </ul>
            
                    <ul class="navbar-nav my-2 my-lg-0">
                    <li class="nav-item">
                        <a class="nav-link dashboard-link" href="https://dashboard.botghost.com/dashboard">Login</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link dashboard-link" href="https://dashboard.botghost.com/dashboard">Sign Up</a>
                      </li>
                    </ul>
                  </div>
                </nav>
            
                <div class="header-content">
                  <div class="row justify-content-center">
                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12 align-self-center">
                      <h1>Build a free Discord bot with no coding required.</h1>
                      <h2 class="mt-2">Make your own Discord bot for free in 5 minutes with no coding required. Choose from over 300 commands to enable Moderation, Utilities, Economy and more</h2>
            
                      <div class="mt-4">
                        <a class="btn btn-large big-button btn-success cta-button dashboard-link"  href="https://dashboard.botghost.com/dashboard"><i class="fab fa-discord"></i> Login with Discord</a>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 d-none d-sm-block">
                      <picture>
                        <source class="home-img" srcset="/img/webp/home_main.webp" type="image/webp"/>
                        <img class="home-img" src="/img/home_main.PNG"/>
                      </picture>
                    </div>
            
                  </div>
                </div>
              </header>
            
              <main>
                <section class="feature-section">
                  <div class="feature-section-inner container">
                        <div class="row justify-content-center">
                          <div class="col-4">
                            <div class="fact-box">
                              <div class="fact-box-inner align-self-center">
                                <h3 class="counter" >{this.state.active || 0}</h3>
                                <p>Active online bots</p>
                              </div>
                            </div>
                          </div>
            
                          <div class="col-4">
                            <div class="fact-box">
                              <div class="fact-box-inner">
                                <h3 class="counter" data-count="<%= data.members %>">0</h3>
                                <p>Discord members handled</p>
                              </div>
                            </div>
                          </div>
            
                          <div class="col-4">
                            <div class="fact-box">
                              <div class="fact-box-inner">
                                <h3 class="counter" data-count = "<%= data.servers %>">0</h3>
                                <p>Servers watched</p>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </section>
                <section class="feature-section" id="features">
                <div class="feature-section-inner container">
                  <div class="row">
                    <div class="col-md-6 order-md-2 order-sm-1 order-xs-1">
                                <picture>
                        <source srcset="/img/webp/home_custom.webp" type="image/webp"/>
                        <img src="/img/home_custom.PNG"/>
                      </picture>
                    </div>
            
                    <div class="col-md-6 order-md-1 order-sm-2 order-xs-2 align-self-center">
                      <div class="home-section-content align-middle">
                      <h2>Fully Customizable</h2>
                      <p>Easily configure your bot on the go with absolutely no coding required. Enable and disable modules to tailor your discord bot to your servers needs. Easily get started by pasting your bots token into your dashboard, saving and going online.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            
              <section class="feature-section">
                <div class="feature-section-inner container">
                  <div class="row">
            
                    <div class="col-md-6 order-md-1 order-xs-1 align-middle">
                                <picture>
                        <source srcset="/img/webp/home_custom_commands.webp" type="image/webp"/>
                        <img src="/img/home_custom_commands.PNG"/>
                      </picture>
                    </div>
            
                    <div class="col-md-6 order-md-2 align-self-center">
                      <div class="home-section-content align-middle">
                      <h2>Create your own commands</h2>
                      <p>Setup your own text, embed and random commands. Give server info, news and more with BotGhost's custom discord commands. Simply input your custom tag, and create a response. Select from variables to make your bot feel more human and personable.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            
            <section className="feature-section">
            <div class="feature-section-inner container">
                  <div class="row">
                    <div class="col-md-6 order-md-1 order-sm-2 align-self-center">
                      <div class="home-section-content align-middle">
                      <h2>Send Announcements to the server</h2>
                      <p>Make new members feel welcome with announcements messages when they join. Create announcements for when users leave or are banned, to notify the rest of the server.</p>
                      </div>
                    </div>
            
                    <div class="col-md-6 order-md-2 order-sm-1 order-xs-1 align-middle">
                                <picture>
                        <source srcset="/img/webp/home_announcement.webp" type="image/webp"/>
                        <img src="/img/home_announcement.PNG"/>
                      </picture>
                    </div>
                  </div>
                </div>
            </section>
            
                  <section class="feature-section">
                <div class="feature-section-inner container">
                  <div class="row">
            
                    <div class="col-md-6 order-md-1 order-xs-1 align-middle">
                      <picture>
                        <source srcset="/img/fortnite.webp" type="image/webp"/>
                        <img src="/img/fortnite.jpg"/>
                      </picture>
                    </div>
            
            
                            <div class="col-md-6 order-md-2 align-self-center">
                      <div class="home-section-content align-middle">
                      <h2>Get Fortnite and Apex Legends Stats</h2>
                      <p>Get updated Fortnite and Apex Legends stats directly from your own discord bot. Either checkout the pros or flex your most recent win with stats from the Tracker Network.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            
                <section class="feature-section">
                <div class="feature-section-inner container">
                  <div class="row">
                    <div class="col-md-6 order-md-1 order-sm-2 align-self-center">
                      <div class="home-section-content align-middle">
                      <h2>Set custom anouncements</h2>
                      <p>Make new members feel welcome with announcements messages when they join. Create announcements for when users leave or are banned, to notify the rest of the server.</p>
                      </div>
                    </div>
            
                    <div class="col-md-6 order-md-2 order-sm-1 order-xs-1 align-middle">
                                <picture>
                        <source srcset="/img/webp/musicbot.webp" type="image/webp"/>
                        <img src="/img/musicbot.PNG"/>
                      </picture>
                    </div>
                  </div>
                </div>
              </section>
            
                    <section class="feature-section">
                <div class="feature-section-inner container">
                  <div class="row">
            
                    <div class="col-md-6 order-md-1 order-xs-1 align-middle">
                      <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/26/Twitch_logo.svg/455px-Twitch_logo.svg.png"/>
                    </div>
                            <div class="col-md-6 order-md-2 align-self-center">
                      <div class="home-section-content align-middle">
                      <h2>Twitch, Twitter and YouTube Notifications</h2>
                      <p>Boost your viewers with instant server wide twitch stream notifications. Alert your subscribers when a new video hits your YouTube Channel. Have your new tweets automatically posted in your server. </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            
                  <section class="feature-section">
                <div class="feature-section-inner container">
                  <div class="row">
                    <div class="col-md-6 order-md-1 order-sm-2 align-self-center">
                      <div class="home-section-content align-middle">
                      <h2>Create a full Discord Economy</h2>
                      <p>Create a fully fleged discord server economy. Enable trading, gambling, automatic role assignment, games and more with BotGhost's economy system.</p>
                      </div>
                    </div>
            
                    <div class="col-md-6 order-md-2 order-sm-1 order-xs-1 align-middle">
                      <picture>
                        <source srcset="/img/webp/econ.webp" type="image/webp"/>
                        <img src="/img/econ.jpg"/>
                      </picture>
                    </div>
                  </div>
                </div>
              </section>
            
                      <section class="feature-section">
                        <div class="feature-section-inner container">
                          <div class="row">
                    
                            <div class="col-md-6 order-md-1 order-xs-1 align-middle">
                    
                              <picture>
                                <source srcset="/img/webp/home_translate.webp" type="image/webp"/>
                                <img src="/img/home_translate.PNG"/>
                              </picture>
                            </div>
                                    <div class="col-md-6 order-md-2 align-self-center">
                              <div class="home-section-content align-middle">
                              <h2>Take control of a range of Utility Features</h2>
                              <p>Get the weather directly in your server. Translate between two languages using Google's translation service.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                    </section>

                    
            
              </main>
            
{/*             
            
            <script
              src="https://code.jquery.com/jquery-3.4.1.min.js"
              integrity="sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo="
              crossorigin="anonymous"></script>
              <script src="/js/bootstrap.min.js"></script>
              <script type="text/javascript">
              var invite = getUrlVars()["invite"]
              if(invite != undefined){
                $(".dashboard-link").each(function(){
                var $this = $(this)
                $this.prop("href","https://dashboard.botghost.com/dashboard?invite="+invite);
                console.log(this)
                })
              }
            
            
              function getUrlVars()
            {
                var vars = [], hash;
                var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
                for(var i = 0; i < hashes.length; i++)
                {
                    hash = hashes[i].split('=');
                    vars.push(hash[0]);
                    vars[hash[0]] = hash[1];
                }
                return vars;
            }
            
                $('.counter').each(function() {
              var $this = $(this),
                  countTo = $this.attr('data-count');
            
              $({ countNum: $this.text()}).animate({
                countNum: countTo
              },
            
              {
            
                duration: 3000,
                easing:'linear',
                step: function() {
                  $this.text(Math.floor(this.countNum));
                },
                complete: function() {
                  $this.text(this.countNum);
                  //alert('finished');
                }
            
              });
            
            
            
            });
              </script> */}
            <script async defer src="https://scripts.simpleanalyticscdn.com/latest.js"></script>
            <noscript><img src="https://queue.simpleanalyticscdn.com/noscript.gif" alt=""/></noscript>
            </body>
            </html>
            
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
