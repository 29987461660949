import React, { Component } from "react";
import { connect } from "react-redux";
import NewHomeNav from "../elements/NewHomeNav";
import ToolContainer from "./ToolContainer";
import server from "../../api/server";
import { LoaderV2 } from "./Loader";
import { ClipboardDocumentIcon } from "@heroicons/react/24/solid";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

var badges = [
	{
		Title: "Supports Commands",
		img_src: "/badges/supportscommands.svg",
		Flag: 8388608
	},
	{
		Title: "Uses Automod",
		img_src: "/badges/automod.svg",
		Flag: 16777216
	},
	{
		Title: "This server has *Bot Name* premium",
		img_src: "/badges/premiumbot.png",
		Flag: "Unknown"
	},
	{
		Title: "Originaly known as",
		img_src: "/badges/username.png",
		Flag: "Unknown"
	},
	{
		Title: "HypeSquad Events",
		img_src: "/badges/hypesquadevents.svg",
		Flag: 4
	},
	{
		Title: "HypeSquad Brilliance",
		img_src: "/badges/hypesquadbrilliance.svg",
		Flag: 128
	},
	{
		Title: "HypeSquad Bravery",
		img_src: "/badges/hypesquadbravery.svg",
		Flag: 64
	},
	{
		Title: "HypeSquad Balance",
		img_src: "/badges/hypesquadbalance.svg",
		Flag: 256
	},
	{
		Title: "Discord Staff",
		img_src: "/badges/discordstaff.svg",
		Flag: 1
	},
	{
		Title: "Partnered Server Owner",
		img_src: "/badges/discordpartner.svg",
		Flag: 2
	},
	{
		Title: "Moderator Programs Alumni",
		img_src: "/badges/discordmod.svg",
		Flag: 262144
	},
	{
		Title: "Early Verified Bot Developer",
		img_src: "/badges/discordbotdev.svg",
		Flag: 131072
	},
	{
		Title: "Active Developer",
		img_src: "/badges/activedeveloper.svg",
		Flag: 4194304
	},
	{
		Title: "Discord Nitro",
		img_src: "/badges/discordnitro.svg",
		Flag: "Unknown"
	},
	{
		Title: "Early Supporter",
		img_src: "/badges/discordearlysupporter.svg",
		Flag: 512
	},
	{
		Title: "Discord Bug Hunter (Normal)",
		img_src: "/badges/discordbughunter1.svg",
		Flag: 8
	},
	{
		Title: "Discord Bug Hunter (Golden)",
		img_src: "/badges/discordbughunter2.svg",
		Flag: 16384
	}
];
export class TimeConverter extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: "",
			loaded: false,
			loading: false,
			time: new Date(),
			timestamp: "",
			copied: "",
		};
	}
	componentDidMount() {
		this.fetchTimeStamp(new Date());
	}

	fetchTimeStamp = (date) => {
		if (date == null) {
			date = new Date();
		}
		console.log("DATE", date);
		var UTC_TIMESTAMP = `${moment(date).unix()}`;
		this.setState({
			time: date,
			timestamp: `${UTC_TIMESTAMP}`
		});
	};





	renderTimeStamps = () => {

		var stamps = [];



		var timestamps = [
			{
				discord_format: `<t:${moment(this.state.time).unix()}:F>`,
				format: "dddd, MMMM D, YYYY h:mm A"
			},
			{
				discord_format: `<t:${moment(this.state.time).unix()}:f>`,
				format: "MMMM D, YYYY h:mm A"
			},
			{
				discord_format: `<t:${moment(this.state.time).unix()}:D>`,
				format: "MMMM D, YYYY"
			},
			{
				discord_format: `<t:${moment(this.state.time).unix()}:d>`,
				// 09/22/2023
				format: "MM/DD/YYYY"
			},
			{
				discord_format: `<t:${moment(this.state.time).unix()}:t>`,
				// 9:36 AM
				format: "h:mm A"
			},
			{
				discord_format: `<t:${moment(this.state.time).unix()}:T>`,
				// 9:36:12 AM
				format: "h:mm:ss A"
			},
			{
				discord_format: `<t:${moment(this.state.time).unix()}:R>`,
				// 0 Seconds ago
				format: "R"
			}

		];


		timestamps.forEach((stamp) => {
			stamps.push(
				<div className="flex flex-row text-white text-lg mb-3 justify-left">
					<CopyToClipboard text={stamp.discord_format} onCopy={() => {
						this.setState({
							copied: stamp.discord_format
						});
					}}>
						<button className="bg-red mr-2 text-white p-2 rounded-md">
							<ClipboardDocumentIcon className="h-4 w-4 text-blue-500" />
						</button>
					</CopyToClipboard>
					<div className="bg-lightGray content-center p-1 rounded-sm min-w-[150px] flex justify-center">{stamp.discord_format}</div>

					<div className="muted-text flex justify-center ml-5 lg:ml-10 text-lg">
						<span>{stamp.format == "R" ? moment(this.state.time).fromNow() : moment(this.state.time).format(stamp.format)}</span>
					</div>
				</div>
			);
		});

		stamps.push(
			<div className="flex flex-row text-white text-lg mb-3 justify-left">
				<CopyToClipboard text={moment(this.state.time).unix()} onCopy={() => {
				}}>
					<button className="bg-red mr-2 text-white p-2 rounded-md">
						<ClipboardDocumentIcon className="h-4 w-4 text-blue-500" />
					</button>
				</CopyToClipboard>
				<div className="bg-lightGray content-center p-1 font-bo rounded-sm min-w-[150px] flex justify-center">{moment(this.state.time).unix()}</div>

				<div className="muted-text flex justify-center ml-5 lg:ml-10 ">
					<span>{moment(this.state.time).unix()}</span>
				</div>
			</div>
		);

		return stamps;
	};

	render() {
		return (
			<ToolContainer title="Discord Time Converter" description="Pick a time and date and automatically convert it to Discord's timestamp format. Times will then be displayed in everyone's local timezone.">
				<div className="lg:px-[25%]">
					<div className="text-center text-white flex justify-center flex-col mb-3">
						<img className="h-[60px]" src={process.env.PUBLIC_URL + "/img/logo-red.png"}></img>
						<h1 className="text-5xl font-bold">Discord Time Converter</h1>
						<p className="muted-text">Pick a time and date and automatically convert it to Discord's timestamp format. Times will then be displayed in everyone's local timezone.</p>
					</div>


					<div className="mt-5 mb-2">
						<div className="flex content-center justify-center">
							<div className="">
								{/* <label htmlFor="email" className="block text-md font-bold leading-6 text-white">
									Day
								</label> */}

								<div className="flex content-center text-xl">
									<DateTimePicker
										onChange={(time) => {
											console.log(time);
											this.fetchTimeStamp(time);
										}}
										disableClock={true}
										className={"bg-white"}
										value={this.state.time}
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="flex justify-center mb-5">
						{this.state.copied != "" ? <span className="text-red text-lg font-bold">Copied {this.state.copied}</span> : null}
					</div>

					<div className="gap-y-10 flex justify-center">
						<div>
							{this.renderTimeStamps()}
						</div>
					</div>
				</div>
			</ToolContainer>
		);
	}
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default TimeConverter;
