import React, { Component } from 'react';
import { connect } from 'react-redux';
import NewHomeNav from '../elements/NewHomeNav';
import ToolContainer from './ToolContainer';
import server from '../../api/server';
import { LoaderV2 } from './Loader';


var badges = [
    {
        "Title": "Supports Commands",
        "img_src": "/badges/supportscommands.svg",
        "Flag": 8388608
    },
    {
        "Title": "Uses Automod",
        "img_src": "/badges/automod.svg",
        "Flag": 16777216
    },
    {
        "Title": "This server has *Bot Name* premium",
        "img_src": "/badges/premiumbot.png",
        "Flag": "Unknown"
    },
    {
        "Title": "Originaly known as",
        "img_src": "/badges/username.png",
        "Flag": "Unknown"
    },
    {
        "Title": "HypeSquad Events",
        "img_src": "/badges/hypesquadevents.svg",
        "Flag": 4
    },
    {
        "Title": "HypeSquad Brilliance",
        "img_src": "/badges/hypesquadbrilliance.svg",
        "Flag": 128
    },
    {
        "Title": "HypeSquad Bravery",
        "img_src": "/badges/hypesquadbravery.svg",
        "Flag": 64
    },
    {
        "Title": "HypeSquad Balance",
        "img_src": "/badges/hypesquadbalance.svg",
        "Flag": 256
    },
    {
        "Title": "Discord Staff",
        "img_src": "/badges/discordstaff.svg",
        "Flag": 1
    },
    {
        "Title": "Partnered Server Owner",
        "img_src": "/badges/discordpartner.svg",
        "Flag": 2
    },
    {
        "Title": "Moderator Programs Alumni",
        "img_src": "/badges/discordmod.svg",
        "Flag": 262144
    },
    {
        "Title": "Early Verified Bot Developer",
        "img_src": "/badges/discordbotdev.svg",
        "Flag": 131072
    },
    {
        "Title": "Active Developer",
        "img_src": "/badges/activedeveloper.svg",
        "Flag": 4194304
    },
    {
        "Title": "Discord Nitro",
        "img_src": "/badges/discordnitro.svg",
        "Flag": "Unknown"
    },
    {
        "Title": "Early Supporter",
        "img_src": "/badges/discordearlysupporter.svg",
        "Flag": 512
    },
    {
        "Title": "Discord Bug Hunter (Normal)",
        "img_src": "/badges/discordbughunter1.svg",
        "Flag": 8
    },
    {
        "Title": "Discord Bug Hunter (Golden)",
        "img_src": "/badges/discordbughunter2.svg",
        "Flag": 16384
    }
];

const FAQS = [
    {
        question: "What is your Discord ID?",
        answer: "To find your Discord ID, you can right-click on your username or profile picture, select 'Copy ID,' and paste it somewhere to view your unique Discord ID."
    },
    {
        question: "How to find discord user id on mobile?",
        answer: "To find your Discord user ID on a mobile device, you can follow the same steps as on desktop. Tap and hold on your username or profile picture, then select the 'Copy ID' option to view your Discord ID."
    },
    {
        question: "How to get server id Discord?",
        answer: "To get a server's ID on Discord, you can right-click on the server name and select 'Copy ID.' This will give you the unique identifier for that server."
    },
    {
        question: "How to find discord id on iPhone?",
        answer: "To find your Discord ID on an iPhone or mobile device, tap and hold on your username or profile picture, then select the 'Copy ID' option to view your Discord ID."
    },
    {
        question: "How to get message id in Discord?",
        answer: "To get a message's ID in Discord, you can right-click on the message and select 'Copy ID' or tap and hold on the message (on mobile) and select the option to copy the message's ID."
    },
    {
        question: "How to copy discord id?",
        answer: "To copy a Discord ID, right-click on the element (e.g., user, server, message) and select 'Copy ID.' This will copy the unique ID to your clipboard."
    },
    {
        question: "How to copy ID on Discord?",
        answer: "To copy an ID on Discord, right-click on the element (e.g., user, server, message) and select 'Copy ID.' This will copy the unique ID to your clipboard."
    },
    {
        question: "How to get role id Discord?",
        answer: "To get a role's ID in Discord, you can right-click on the role and select 'Copy ID.' This will copy the unique ID for that role."
    },
    {
        question: "How to find user id on Discord?",
        answer: "To find a user's ID on Discord, you can right-click on their username and select 'Copy ID.' This will copy the unique user ID to your clipboard."
    },
    {
        question: "How to find server id Discord?",
        answer: "To find a server's ID in Discord, right-click on the server name and select 'Copy ID.' This will copy the unique identifier for that server."
    },
    {
        question: "How to get discord id on mobile?",
        answer: "To get your Discord ID on a mobile device, tap and hold on your username or profile picture, then select the 'Copy ID' option to view your Discord ID."
    },
    {
        question: "How to get channel id Discord?",
        answer: "To get a channel's ID in Discord, you can right-click on the channel and select 'Copy ID.' This will copy the unique ID for that channel."
    },
    {
        question: "How to check your Discord ID?",
        answer: "You can check your Discord ID by right-clicking on your username or profile picture, selecting 'Copy ID,' and pasting it somewhere to view your unique Discord ID."
    },
    {
        question: "Where is my Discord ID?",
        answer: "Your Discord ID can be found by right-clicking on your username or profile picture, selecting 'Copy ID,' and pasting it somewhere to view your unique Discord ID."
    },
    {
        question: "How to find someone on Discord with ID?",
        answer: "To find someone on Discord using their ID, you typically need to have their ID provided to you by the user. Once you have their ID, you can send them a friend request or contact them directly."
    },
    {
        question: "How to message someone on Discord with their ID?",
        answer: "To send a message to someone on Discord using their ID, you can add them as a friend by entering their ID in the 'Add Friend' section or by clicking on the 'Friends' icon and selecting 'Add Friend.' Then, you can send them a direct message."
    }
];

export class IdLookup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: "",
            loaded: false,
            loading: false,
            error: null,
            result: {
                name: "tomtoise",
                avatar: "https://cdn.discordapp.com/avatars/136327647792726016/c09224ebac192909a9151ea5e9a41535.png?size=1024",
                banner: "https://cdn.discordapp.com/banners/136327647792726016/50d6f63876081812ec5dc05986302c9a.png?size=1024",
                bot: true,
                memberSince: "Jan 12, 2016"
            }
        };
    }

    lookup = async () => {
        this.setState({
            loading: true,
            loaded: false,
            error: null
        });
        var response = await server.get("/public/tools/user_lookup/" + this.state.id).catch(e => {

        });

        if (response && response.data) {
            this.setState({ result: response.data, loaded: true, loading: false });
        } else {
            this.setState({ loaded: false, loading: false, error: "Could not find a user with that id" });
        }


    };

    convertIDtoTimeStamp = () => {
        const milliseconds = BigInt(this.state.result.id) >> 22n;
        var date = new Date(Number(milliseconds) + 1420070400000);
        return date.toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' });
        // return new Date(Number(milliseconds) + 1420070400000);

    };


    renderBadges = () => {
        var return_badges = [];

        badges.forEach(badge => {
            if ((this.state.result.public_flags & badge.Flag) == badge.Flag && badge.Flag != "Unknown" && badge.Flag != 0) {
                return_badges.push(<img title={badge.Title} className='h-[35px] inline-block' src={process.env.PUBLIC_URL + "/img" + badge.img_src}></img>);
            }
        });


        return return_badges;
    };
    render() {
        return (
            <ToolContainer faqs={FAQS} title="Discord User ID Lookup" description="Lookup information about a User using their unique Discord User ID.">
                <div className='lg:px-[25%]'>
                    <div className="text-center text-white flex justify-center flex-col mb-3">
                        <img className='h-[60px]' src={process.env.PUBLIC_URL + '/img/logo-red.png'}></img>
                        <h1 className='text-5xl font-bold'>Discord User ID Lookup</h1>
                        <p className='muted-text'>Lookup information about a User using their unique Discord User ID.</p>
                    </div>


                    <div className='mt-5'>
                        <div>
                            <div>
                                <label htmlFor="email" className="block text-md font-bold leading-6 text-white">
                                    User ID
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="text"
                                        id="text"
                                        value={this.state.id}
                                        onChange={(e) => this.setState({ id: e.target.value })}
                                        className="block w-full rounded-md border-0 py-2 px-2 text-lg text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        placeholder="136327647792726016"
                                    />
                                </div>
                            </div>

                            <div>
                                <button className='btn btn-red mt-2' onClick={() => {
                                    this.lookup();
                                }}>Lookup</button>
                            </div>
                        </div>
                    </div>

                    <div className='text-red mt-5 font-bold text-xl'>
                        {this.state.error}
                    </div>


                    {this.state.loaded ? <div className='mt-5'>
                        <h2 className='text-4xl font-bold text-white'>Result</h2>

                        <div>
                            <div className='relative'>
                                {
                                    this.state.result.banner ? <img onClick={() => {
                                        window.open(`https://cdn.discordapp.com/banners/${this.state.result.id}/${this.state.result.banner}.png?size=1024`);
                                    }} className='hover:cursor-pointer hover:opacity-60' src={`https://cdn.discordapp.com/banners/${this.state.result.id}/${this.state.result.banner}.png?size=1024`} /> : <div className={`h-[250px] bg-[${this.state.result.banner_color ? this.state.result.banner_color : "red"}]`}></div>
                                }
                                <img onClick={() => {
                                    window.open(`https://cdn.discordapp.com/avatars/${this.state.result.id}/${this.state.result.avatar}.png?size=1024`);
                                }} className='h-[150px] rounded-full absolute bottom-[-75px] left-[25px] hover:cursor-pointer hover:border-red' src={`https://cdn.discordapp.com/avatars/${this.state.result.id}/${this.state.result.avatar}.png?size=1024`} ></img>
                            </div>

                            <div className='bg-lightGray px-5 py-[100px]'>

                                <div className='flex items-center'>
                                    <div>
                                        <p className='text-white font-bold text-2xl'>{this.state.result.global_name}</p>
                                        <p className='muted-text align-left'>{this.state.result.username}</p>
                                    </div>
                                    <div className='ml-auto'>
                                        {this.renderBadges()}
                                    </div>
                                    {/* {this.state.result.bot ? <span className=" ml-2 inline-flex items-center rounded-md bg-[#5865F2] px-2 py-1 text-sm text-white font-bold ring-1 ring-inset ring-gray-500/10">
                                        BOT
                                    </span> : null} */}
                                </div>

                                <div className='mt-3 flex gap-x-6'>
                                    <div>
                                        <p className='text-white opacity-75 text-sm font-bold'>MEMBER SINCE</p>
                                        <div>
                                            <p className='text-white font-bold text-xl'>{this.convertIDtoTimeStamp()}</p>
                                        </div>
                                    </div>

                                    <div>
                                        <p className='text-white opacity-75 text-sm font-bold'>USER ID</p>
                                        <div>
                                            <p className='text-white font-bold text-xl'>{this.state.result.id}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div> : null}

                    {
                        this.state.loading ? <LoaderV2 text="Loading User" /> : null
                    }


                </div>

                <div>
                    <div class="max-w-2xl mx-auto p-6 text-white  rounded-lg">
                        <h1 class="text-2xl font-semibold mb-4">To get a User ID you must first enable Discord Developer mode. To do so, follow these steps:</h1>
                        <ol class="list-decimal ml-6 mb-4">
                            <li class="mb-2">
                                <strong class="font-semibold">Open Discord:</strong> Launch the Discord desktop app or open the Discord website and log in to your account.
                            </li>
                            <li class="mb-2">
                                <strong class="font-semibold">User Settings:</strong> Click on your profile picture or username in the lower-left corner to access your User Settings.
                            </li>
                            <li class="mb-2">
                                <strong class="font-semibold">Appearance:</strong> In the User Settings menu, click on the "Appearance" tab on the left sidebar.
                            </li>
                            <li class="mb-2">
                                <strong class="font-semibold">Advanced:</strong> Scroll down the Appearance settings until you find the "Advanced" section.
                            </li>
                            <li class="mb-2">
                                <strong class="font-semibold">Toggle Developer Mode:</strong> In the Advanced section, you'll see an option called "Developer Mode." Toggle the switch to enable it. When enabled, Developer Mode is indicated by a checkmark.
                            </li>
                        </ol>
                        <p>
                            Once Developer Mode is enabled, you can right-click on various elements within Discord (e.g., users, messages, servers) to access additional options and features, which can be helpful for tasks such as reporting issues or finding user IDs.
                        </p>
                        <p class="mt-4">
                            <strong>Please note:</strong> Developer Mode is primarily intended for developers and advanced users who may need additional functionality for debugging or reporting problems in Discord.
                        </p>
                    </div>
                </div>

            </ToolContainer >
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default IdLookup;