export const MODULE_IMAGES = {
    moderation:[
        {title:"Use and customize any command.",text:"All BotGhost commands are fully customizable. Change the name, description, permissions, cooldown and more.",img:"moderation_invite.gif"},
        {title:"Moderate your server with commands.",text:"Allow members to moderate your server through powerful commands. Mute, ban, unban and warn members all through your own custom made bot. ",img:"moderation_unmute.gif"},
    ],
    announcements:[
        {text:"Create new user, ban and leave announcements. ",title:"Create custom plain text and embed announcements.",img:"announcement.gif"},
        {text:"Tailor your announcements specifically to your servers needs within your discord bot's dashboard.",title:"Create new announcements easily from within your dashboard.",img:"Announcement_2.gif"},
    ],
    automod:[
        {text:"Set up an automoderation system on your discord bot. Enable Bad Word, Links, Emoji, Server Invite, Mass Caps and Mentions detection.",title:"Keep your server safe and clean with automoderation. No humans required.",img:"Automod_1.gif"},
        {text:"Tailor the Automoderator to your servers needs. Set up ignored channels, whitelist roles and more.",title:"Customize your automoderator to your servers needs.",img:"Automod_2.gif"},
    ],
    'auto-role':[
        {text:"Add roles to be assigned to members when they join your server. ",title:"Customize your Auto Role bot using our easy to use dashboard.",img:"autorole_1.gif"},
        {text:"Set roles to be added to members who pass your membership screening. ",title:"Verify users when they accept your Membership Screening",img:"autorole_2.gif"},
    ],
    'levels':[
        {text:"Access stats about your leveling system in discord. See your current exp and leaderboard rank.",title:"Use slash commands to see your level and current rank!",img:"Levels_1.gif"},
        {text:"Create your own leveling system, with custom level up messages and role rewards.",title:"Setup your own unique leveling system, with hosted leaderboard.",img:"Levels_2.gif"},
    ],
    'meme-creator':[
        {text:"Create your own memes using ImgFlips meme templates.",title:"Create memes from ImgFlip using your bots commands.",img:"Meme_1.gif"},
        {text:"Share the memes you create with your discord server.",title:"Access hundreds of meme templates..",img:"Meme_2.gif"},
    ],
    'music':[
        {text:"Get music direct from YouTube",title:"Stream high quality music to your server.",img:"Music_1.gif"},
    ],
    'search':[
        {text:"Search from YouTube, Twitch and Twitter.",title:"Search YouTube and other platforms directly within your Discord Server.",img:"Search_1.gif"},
        {text:"Scour through Imgur and Giphy using easy to use customizable slash commands.",title:"Find cat pics on Imgur.",img:"Search_2.gif"},
    ],
    'timed-messages':[
        {text:"Create schedules to automatically post text and embed messages to your server.",title:"Set up schedules to post messages automatically.",img:"TimedMessages_1.gif"},
        {text:"Edit your messages in your bots dashboard.",title:"Easily create timed messages using your bot's dashboard.",img:"TimedMessages_2.gif"},
    ],
    'translator':[
        {text:"Translate between hundrends of languages. Your bot will automatically detect the language you are typing in.",title:"Turn your bot into a translator using Google Translate.",img:"Translator1.gif"},
    ],
    'weather':[
        {text:"Turn your bot into a weather man. Get the weather before heading outside.",title:"Get the weather from your own discord bot.",img:"weather_1.gif"},
    ],
    'youtube':[
        {text:"Add as many YouTube trackers as you need. Make sure your audience never misses another upload.",title:"Alert your server members when you post a new video on YouTube.",img:"Youtube_1.gif"},
    ],
}