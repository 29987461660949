import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Router } from 'react-router-dom';
import { App } from './App';
// import {Provider} from 'react-redux'
// import {createStore,applyMiddleware,compose} from 'redux'
// import reduxThunk from 'redux-thunk'

// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";




// import reducers from './reducers';
import "./index.css";
import history from './history';

// import ReactGA from 'react-ga';

// ReactGA.initialize("UA-122665050-1",{
//   gaOptions:{
//     cookieDomain:"auto"
//   }
// });

// history.listen(location => {
//     ReactGA.set({ page: location.pathname }); // Update the user's current page
//     ReactGA.pageview(location.pathname); // Record a pageview for the given page
//   });


// ReactGA.set({
//   userId: auth.currentUserId(),

// })

ReactDOM.render(
    <BrowserRouter history={history}>
        <App />
    </BrowserRouter>


    , document.querySelector("#root"));
