import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

export class Nav extends Component {
    render() {
        return (
            <div className="topnav">
                <nav className="nav">
                    <a className="nav-left" href="/marketplace">
                        <img src={process.env.PUBLIC_URL + "/img/logo-red.png"}></img>
                        <h2>Marketplace</h2>
                    </a>


                    

                    <div className="flex search">
                        <div className="flex flex-col justify-center">
                            <div className="main-search">

                                    <FontAwesomeIcon color={"#707a83"} icon={faSearch}/>
                                    <input type="text" placeholder="Search for Bots, Commands, Servers and more"></input>

                                
                                {/* <input type="text"></input> */}
                            </div>
                        </div>
                    </div>   

                    <div className="nav-right">

                        <ul>
                            <li>Commands</li>
                            <li>Bots</li>
                            <li>Servers</li>
                            <li>Templates</li>
                        </ul>
                    </div>
                </nav>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(Nav)
