import React, { Component } from 'react';
import { connect } from 'react-redux';

export class ToolTextArea extends Component {
    render() {
        return (
            <div>
                <label htmlFor="email" className="block text-md font-bold leading-6 text-white">
                    {this.props.label} {this.props.maxLength ? <span className='muted-text opacity-50'>{this.props.value.length}/{this.props.maxLength}</span> : null}
                </label>
                <div className="mt-2">
                    <textarea
                        type={this.props.type ? this.props.type : "text"}
                        name="text"
                        id="text"
                        value={this.props.value}
                        maxLength={this.props.maxLength ? this.props.maxLength : 2000}
                        onChange={(e) => {
                            this.props.onChange(e.target.value);
                        }}
                        className={`block w-full text-white ${this.props.bg ? this.props.bg : 'bg-lightGray'}  rounded-md border-0 py-2 px-2 text-lg text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}

                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default ToolTextArea;