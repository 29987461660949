import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Link} from "react-router-dom"
import ReactGA from 'react-ga';
import {faBars} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NewHomeNav from './elements/NewHomeNav';
import Cookies from 'universal-cookie';
import  HomeCommandBuilder  from './elements/HomeCommandBuilder';
import CountUp from 'react-countup';
import server from '../api/server';
import { Helmet } from 'react-helmet';

const AllModules = [
	{
		name: "Server Management",
		modules: [
			{
				name: "Moderation",
				description: "Moderate your server with a set of powerful commands",
				icon: "moderation.png",
				premium: false
			},
			{
				name: "Announcements",
				description: "Create automatic announcements for unique events in your server!",
				icon: "announcements.png",
				premium: false
			},
			{
				name: "Automod",
				description: "Automatically moderate your servers text channels. No humans required.",
				icon: "automoderator.png",
				premium: false
			},
			{
				name: "Custom Commands",
				description: "Create custom commands to personalize your bot!",
				icon: "customcommands.png",
				premium: false
			},
			{
				name: "Auto-Role",
				description: "Automatically assign roles to users when they join your server.",
				icon: "autorole.png",
				premium: false
			},
			// {
			// 	name: "Usage",
			// 	description: "Change how your bot operates and how members use your bot.",
			// 	icon: "operation.png",
			// 	premium: false
			// },
			{
				name: "Timed Messages",
				description: "Automatically send timed messages to your server.",
				icon: "messenger.png",
				premium: false
			},
			// {
			// 	name: "Status",
			// 	description: "Replace the 'Created on BotGhost.com' status with your own.",
			// 	icon: "wrench.png",
			// 	premium: true
			// },
			{
				name: "Reaction Roles",
				description: "Setup a Reaction Role system",
				icon: "reactroles.svg",
				premium: true
			},
		]
	},
	{
		name: "Social Integrations",
		modules: [
			{
				name: "YouTube",
				description: "Receive notifications when you or someone else posts a new video",
				icon: "youtube.png",
				premium: true
			},
			{
				name: "Twitter",
				description: "Have new tweets automatically posted in your server.",
				icon: "twitter.png",
				premium: true
			},
			{
				name: "Reddit",
				description: "Get new post alerts directly in your discord server.",
				icon: "reddit.png",
				premium: true
			},
			{
				name: "Twitch",
				description: "Setup stream notifications so your members never miss when you go online.",
				icon: "twitch.png",
				premium: true
			},
		]
	},
	{
		name: "Fun",
		modules: [
			{
				name: "Meme Creator",
				description: "Create memes using templates from ImgFlip.",
				icon: "memecreator.webp",
				premium: false
			},
			{
				name: "Levels",
				description: "Create a leveling system and hosted leaderboard for your server.",
				icon: "levels.png",
				premium: false
			},
			{
				name: "Music",
				description: "Listen to music directly in your discord server.",
				icon: "music.png",
				premium: true
			},
			{
				name: "Economy",
				description: "Set up a fully fledged server economy",
				icon: "economy.png",
				premium: true
			},
			{
				name: "Trivia",
				description: "Test your wits against fellow members",
				icon: "trivia.png",
				premium: true
			},
		]
	},
	{
		name: "Utilities",
		modules: [
			{
				name: "Search",
				description: "Search the Internet for a variety of things",
				icon: "search.png",
				premium: false
			},
			{
				name: "Weather",
				description: "get the weather before heading outside",
				icon: "weather.png",
				premium: true
			},
			{
				name: "Translator",
				description: "Translate messages with built in Google Translator",
				icon: "translator.png",
				premium: true
			},
			// {
			// 	name: "Co Create",
			// 	description: "Invite other people to edit and work on your bot.",
			// 	icon: "co-create.png",
			// 	premium: true
			// },
			// {
			// 	name: "Botlist",
			// 	description: "Advertise your bot on BotGhost",
			// 	icon: "botlist.png",
			// 	premium: true
			// },
		]
	},
	{
		name: "Game Integrations",
		modules: [
			{
				name: "Fortnite",
				description: "Get Fortnite stats directly from Fortnite Tracker",
				icon: "fortnite.png",
				premium: true
			},
			{
				name: "Apex Legends",
				description: "Share your latest stats directly from Apex Tracker",
				icon: "apex.png",
				premium: true
			},
		]
	}
]



export class ContentGapTemplate extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             menuOpen:false,
             invite:null,
             stats:{
                 new_bots:0,
                 new_users:0,
                 total_bots:0
             }
        }
    }

    componentDidMount() {
        console.log(this.props)
        var invite = this.getUrlVars()['invite']

        var partner = this.getUrlVars()['partner']
        console.log(partner)
        console.log(invite)

        if(partner != undefined){
            const cookies = new Cookies();
            cookies.set("partner",partner,{ path: '/',domain:".botghost.com" })
            console.log("Setting partner cookie")
        }
        
        if(invite != undefined){
            const cookies = new Cookies();
            cookies.set("invite",invite,{ path: '/',domain:".botghost.com" })
            console.log("Setting invite cookie")
        }
        
        ReactGA.pageview(window.location.pathname)
        this.getStats()
    }

    getStats = async () => {
        var response = await server.get("/home/stats");
        if(response.data){
            this.setState({
                stats:response.data
            })
        }

    }
    
    renderModules = () =>{
        const categories = []
        AllModules.forEach(category =>{
            const categoryModules = []
            category.modules.forEach(categoryModule =>{
                categories.push(
                    <div className="col-xl-2 col-lg-2 col-sm-2 col-3">
                        <a href="#modules">
                            <img class="home-module-icon" src={`/img/icons/${categoryModule.icon}`}/>

                        </a>
                    </div>
    
                )
            })
    
        })
    
        return categories
    }

     getUrlVars = () =>
    {
        var vars = [], hash;
        var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for(var i = 0; i < hashes.length; i++)
        {
            hash = hashes[i].split('=');
            vars.push(hash[0]);
            vars[hash[0]] = hash[1];
        }
        return vars;
    }


    renderModuleInfo = (name) =>{
        const categories = []
        AllModules.forEach(category =>{
            const categoryModules = []
            if(category.name == name){
                category.modules.forEach(categoryModule =>{
                    categories.push(
                        <div className="col-xl-3 col-lg-6 col-md-6">
                            <div className="home-module">
                                <img src={process.env.PUBLIC_URL + `/img/icons/${categoryModule.icon}`}></img>
                                <h4>{categoryModule.name}</h4>
                                <p>{categoryModule.description}</p>
                            </div>
                        </div>
        
                    )
                })
            }
        })
    
        return categories
    }

    showMenu = () =>{
        this.setState({menuOpen:!this.state.menuOpen})
      }

    renderDashboardURL = () =>{
        // if(this.state.invite != null){
            // return `https://dashboard.botghost.com/dashboard/?invite=${this.state.invite}`
        // }else{
            return "https://dashboard.botghost.com/dashboard/"
        // }
    }


    render() {
        return (
            <main>
                <Helmet>
                    <title>Discord Music Bot - Discord Bot Maker</title>

                </Helmet>
                <div>
                <NewHomeNav/>
                                    <HomeCommandBuilder/>
                <div className="home-container home-mobile-only">


                    <div className="home-header-content">
                        <div className="home-header-container">
                            <div style={{width:"35%"}} className="home-header-title">
                                <div>
                                <h1>Build a Discord Music Bot with no coding required.</h1>
                                <p>Make your own Discord bot for free in 5 minutes with no coding required. Choose from over 300 commands to enable Moderation, Utilities, Economy and more</p>
                                <div className="header-buttons">
                                    <a className="btn btn-red mr-5" style={{marginRight:"15px"}} href={this.renderDashboardURL()}>Create a bot</a>
                                    <a className="btn btn-gray" href="#modules">Explore</a>
                                </div>
                            
                                </div>
                            </div>

                            <div className="home-header-modules" style={{width:"60%", height:"100%", marginLeft:"auto"}}>

                                <div className="row justify-content-center">
                                    {this.renderModules()}

                                
                                </div>
                            
                            </div>
                        </div>

                    </div>

                    
                </div>
                {/* <div class="wave">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                         <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
                </svg>
            </div> */}
                <div className="home-stats-banner">
                    {this.state.stats.total_bots == 0 ? null:
                                        <div className="row stat-box-row">
                                        <div className="col-xl-4">
                                            <div className="stat-box">
                                                <div>
                                                    <h3><CountUp  
                                                        separator=","
                                                         start={0} 
                                                     end={this.state.stats.total_bots}/></h3>
                                                    <p>Bots Created</p>
                                                </div>
                                            </div>
                                        </div>
                
                                        <div className="col-xl-4">
                                            <div className="stat-box">
                                                <div>
                                                    <h3>+<CountUp  
                                                        separator=","
                                                         start={0} 
                                                     end={this.state.stats.new_bots}/></h3>
                                                    <p>Bots created this week</p>
                                                </div>
                                            </div>
                                        </div>
                
                                        <div className="col-xl-4">
                                            <div className="stat-box">
                                                <div>
                                                    <h3>+<CountUp  
                                                        separator=","
                                                         start={0} 
                                                     end={this.state.stats.new_users}/></h3>
                                                    <p>New users this week</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                </div>
                <div class="wave">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                    </svg>
                </div>


                <div className="home-info-section home-info-ordering">



                    <section>
                        <div className="home-info-section-inner">
                            <div className="home-info-part">
                                <div>
                                    <h2>24/7  Bot Hosting</h2>
                                    <p>Ensure your bot stays online with 24/7 free hosting. No downloaded program or app required.</p>
                                </div>    
                            </div>

                            <div className="home-info-part">
                                <div>
                                    <img style={{maxHeight:"500px",width:"60%"}} src={process.env.PUBLIC_URL + "/img/home_images/online.PNG"}></img>
                                </div>

                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="home-info-section-inner reverse-order">

                        <div className="home-info-part">
                                <div>
                                    <img src={process.env.PUBLIC_URL + "/img/home_images/multibot.PNG"}></img>
                                </div>

                            </div>


                            <div className="home-info-part">
                                <div>
                                    <h2>Deploy Multiple Bots</h2>
                                    <p>
                                        Deploy and manage as many Discord Bots as you please. Create a bot for any occasion.
                                    </p>
                                </div>    
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="home-info-section-inner">
                            <div className="home-info-part">
                                <div>
                                    <h2>Fully Customizable</h2>
                                    <p>Easily configure your bot on the go with absolutely no coding required. Enable and disable modules to tailor your discord bot to your servers needs. Easily get started by pasting your bots token into your dashboard, saving and going online.</p>
                                </div>    
                            </div>

                            <div className="home-info-part">
                                <div>
                                    <img src={process.env.PUBLIC_URL + "/img/home_images/commands.PNG"}></img>
                                </div>

                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="home-info-section-inner reverse-order">

                        <div className="home-info-part">
                                <div>
                                    <img src={process.env.PUBLIC_URL + "/img/home_images/custom commands.PNG"}></img>
                                </div>

                            </div>


                            <div className="home-info-part">
                                <div>
                                    <h2>Design your own commands</h2>
                                    <p>Setup your own text, embed and random commands. Give server info, news and more with BotGhost's custom discord commands. Simply input your custom tag, and create a response. Select from variables to make your bot feel more human and personable.</p>
                                </div>    
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="home-info-section-inner">
                            <div className="home-info-part">
                                <div>
                                    <h2>Send Announcements to the server</h2>
                                    <p>Make new members feel welcome with announcements messages when they join. Create announcements for when users leave or are banned, to notify the rest of the server.</p>
                                </div>    
                            </div>

                            <div className="home-info-part">
                                <div>
                                    <img src={process.env.PUBLIC_URL + "/img/home_images/announcement.PNG"}></img>
                                </div>

                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="home-info-section-inner reverse-order">

                        <div className="home-info-part">
                                <div>
                                    <img src={process.env.PUBLIC_URL + "/img/home_images/modules.PNG"}></img>
                                </div>

                            </div>


                            <div className="home-info-part">
                                <div>
                                    <h2>Explore 20+ Modules</h2>
                                    <p>Moderate your Discord Server, Integrate with Social Media, Play games and level up or access a range of utilities. </p>
                                </div>    
                            </div>
                        </div>
                    </section>



                </div>

                <div class="wave">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                         <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
                </svg>
            </div>
                <div id="modules" className="home-stats-banner"> 
                    <div className="home-botlist-section">
                        <h2>Utilise over 20 customizable modules</h2>

                        <div className="row">
                            <div className="col-xl-3">

                            </div>
                        </div>
                    </div>
                </div>

                <div class="wave">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                    </svg>
                </div>


                <div className="home-info-section home-modules-section">
                <section>
                        <div className="home-info-section-inner">

                            <div className="home-module-part-header">
                                <div>
                                    <h2>Manage your Server with powerful commands</h2>
                                </div>    
                            </div>

                            <div className="home-module-part-content home-module-left">
                                <div className="row">
                                    {this.renderModuleInfo("Server Management")}
                                </div>
                            </div>
                        </div>
                    </section>


                    <section>
                        <div className="home-info-section-inner">                           
                            <div className="home-module-part-header">
                                <div>
                                    <h2>Integrate with your favourite social media platforms</h2>
                                </div>    
                            </div>

                                <div className="home-module-part-content home-module-left">
                                        <div className="row">
                                            {this.renderModuleInfo("Social Integrations")}
                                        </div>
                                </div>

                        </div>
                    </section>

                    <section>
                        <div className="home-info-section-inner">                           
                            <div className="home-module-part-header">
                                <div>
                                    <h2>An entire suite of Entertainment for your Discord Server</h2>
                                </div>    
                            </div>

                                <div className="home-module-part-content home-module-left">
                                        <div className="row">
                                            {this.renderModuleInfo("Fun")}
                                        </div>
                                </div>

                        </div>
                    </section>

                    <section>
                        <div className="home-info-section-inner">                           
                            <div className="home-module-part-header">
                                <div>
                                    <h2>Access a range of convenient modules and utilities</h2>
                                </div>    
                            </div>

                                <div className="home-module-part-content home-module-left">
                                        <div className="row">
                                            {this.renderModuleInfo("Utilities")}
                                        </div>
                                </div>

                        </div>
                    </section>

                    <section>
                        <div className="home-info-section-inner">                           
                            <div className="home-module-part-header">
                                <div>
                                    <h2>Get stats from popular games directly in your Discord Server</h2>
                                </div>    
                            </div>

                                <div className="home-module-part-content home-module-left">
                                        <div className="row">
                                            {this.renderModuleInfo("Game Integrations")}
                                        </div>
                                </div>

                        </div>
                    </section>
                </div>

                <div className="home-stats-banner"> 
                    <div className="home-botlist-section">
                        <div className="home-botlist-footer-pre">
                            <h2>Start Creating your Own Bot for free</h2>
                            <a className="btn btn-gray" href="https://dashboard.botghost.com/dashboard/">Create Your Own Bot</a>

                        </div>

                    </div>
                    
                </div>

                <footer style={{padding:"15px",display:"flex", backgroundColor:"#222527"}}>
                    <div className="home-footer" style={{marginLeft:"auto"}}>
                    <a href="/privacy-policy" style={{marginRight:"15px"}}>Prviacy Policy</a>
                    <a href="/tos">Terms of Service</a>
                    </div>

                </footer>


                {/* <footer className="home-info-section ">

                </footer> */}

                </div>


                
            </main>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default ContentGapTemplate
