import React, { Component } from 'react'
import { connect } from 'react-redux'
import ModuleCommand from './elements/ModuleCommand'
import NewHomeNav from './elements/NewHomeNav'
import NewModuleSection from './elements/NewModuleSection'
import server from "../api/server"
import { Helmet } from 'react-helmet'

import { MODULE_IMAGES } from '../variables'
import { NewModuleDefault } from './NewModuleDefault'
import ReactGA from 'react-ga';
const AllModules = [
	{
		name: "Server Management",
		modules: [
			{
				name: "Moderation",
				description: "Moderate your server with a set of powerful commands",
				icon: "moderation.png",
				premium: false
			},
			{
				name: "Announcements",
				description: "Create automatic announcements for unique events in your server!",
				icon: "announcements.png",
				premium: false
			},
			{
				name: "Automod",
				description: "Automatically moderate your servers text channels. No humans required.",
				icon: "automoderator.png",
				premium: false
			},
			{
				name: "Custom Commands",
				description: "Create custom commands to personalize your bot!",
				icon: "customcommands.png",
				premium: false
			},
			{
				name: "Auto-Role",
				description: "Automatically assign roles to users when they join your server.",
				icon: "autorole.png",
				premium: false
			},
			// {
			// 	name: "Usage",
			// 	description: "Change how your bot operates and how members use your bot.",
			// 	icon: "operation.png",
			// 	premium: false
			// },
			{
				name: "Timed Messages",
				description: "Automatically send timed messages to your server.",
				icon: "messenger.png",
				premium: false
			},
			// {
			// 	name: "Status",
			// 	description: "Replace the 'Created on BotGhost.com' status with your own.",
			// 	icon: "wrench.png",
			// 	premium: true
			// },
			{
				name: "Reaction Roles",
				description: "Setup a Reaction Role system",
				icon: "reactroles.svg",
				premium: true
			},
		]
	},
	{
		name: "Social Integrations",
		modules: [
			{
				name: "YouTube",
				description: "Receive notifications when you or someone else posts a new video",
				icon: "youtube.png",
				premium: true
			},
			{
				name: "Twitter",
				description: "Have new tweets automatically posted in your server.",
				icon: "twitter.png",
				premium: true
			},
			{
				name: "Reddit",
				description: "Get new post alerts directly in your discord server.",
				icon: "reddit.png",
				premium: true
			},
			{
				name: "Twitch",
				description: "Setup stream notifications so your members never miss when you go online.",
				icon: "twitch.png",
				premium: true
			},
		]
	},
	{
		name: "Fun",
		modules: [
			{
				name: "Meme Creator",
				description: "Create memes using templates from ImgFlip.",
				icon: "memecreator.webp",
				premium: false
			},
			{
				name: "Levels",
				description: "Create a leveling system and hosted leaderboard for your server.",
				icon: "levels.png",
				premium: false
			},
			{
				name: "Music",
				description: "Listen to music directly in your discord server.",
				icon: "music.png",
				premium: true
			},
			{
				name: "Economy",
				description: "Set up a fully fledged server economy",
				icon: "economy.png",
				premium: true
			},
			{
				name: "Trivia",
				description: "Test your wits against fellow members",
				icon: "trivia.png",
				premium: true
			},
		]
	},
	{
		name: "Utilities",
		modules: [
			{
				name: "Search",
				description: "Search the Internet for a variety of things",
				icon: "search.png",
				premium: false
			},
			{
				name: "Weather",
				description: "get the weather before heading outside",
				icon: "weather.png",
				premium: true
			},
			{
				name: "Translator",
				description: "Translate messages with built in Google Translator",
				icon: "translator.png",
				premium: true
			},
			// {
			// 	name: "Co Create",
			// 	description: "Invite other people to edit and work on your bot.",
			// 	icon: "co-create.png",
			// 	premium: true
			// },
			// {
			// 	name: "Botlist",
			// 	description: "Advertise your bot on BotGhost",
			// 	icon: "botlist.png",
			// 	premium: true
			// },
		]
	},
	{
		name: "Game Integrations",
		modules: [
			{
				name: "Fortnite",
				description: "Get Fortnite stats directly from Fortnite Tracker",
				icon: "fortnite.png",
				premium: true
			},
			{
				name: "Apex Legends",
				description: "Share your latest stats directly from Apex Tracker",
				icon: "apex.png",
				premium: true
			},
		]
	}
]


export class NewModule extends Component {

    componentDidMount() {
        this.getData()
        ReactGA.pageview(window.location.pathname)
    }

    constructor(props) {
        super(props)
    
        this.state = {
            name:"",
            commands:[],
            description:"",
            tags:[],
            img:"",
            sections:[]
        }
    }
    


    getData = async () =>{
        console.log(this.props)
        const response = await server.get(`/newmodules/${this.props.match.params.slug}`)
        if(response.data != false){
            var data = response.data;
            var sections = []
            data.sections.forEach(section =>{
                if(MODULE_IMAGES[section] != undefined){
                    var images = MODULE_IMAGES[section];
                    sections = sections.concat(images);
                }
            })
            var tags = [
                `${response.data.name} discord bot`,
                `${response.data.name} discord bot maker`,
                "discord bot",
                "free discord bot",
                `${response.data.name} bot`,
                
            ]
            response.data.tags = tags;
            response.data.sections = sections;
            this.setState(response.data)
        }else{
            window.location.replace("/")
        }
        // if(response.data){
        //     this.setState(response.data)
        // }
    }

    renderSections = () =>{
        var sections = [];
        this.state.sections.forEach((section,index) =>{
            sections.push(
                <NewModuleSection odd={this.isOdd(index)} img={section.img} title={section.title} text={section.text}/>
            )
        })

        return sections
    }

    renderCommands = () =>{
        var commands = []
        this.state.commands.forEach(command =>{
            commands.push(
                <div className='col-xl-4'>
                    <ModuleCommand name={command.command} description={command.description}/>
                </div>
            )
        })
        return commands;

    }

    isOdd = (num) =>{
        return num % 2;
    }

    renderModules = () =>{
        const categories = []
        AllModules.forEach(category =>{
            const categoryModules = []
            category.modules.forEach(categoryModule =>{
                categories.push(
                    <div className="col-xl-2 col-lg-2 col-sm-2 col-3">
                        <a href="#modules">
                            <img class="home-module-icon" src={`/img/icons/${categoryModule.icon}`}/>

                        </a>
                    </div>
    
                )
            })
    
        })
    
        return categories
    }


    render() {
        return (
            <main>
                <Helmet>
                    <title>{this.state.name} Discord Bot Maker | BotGhost</title>
                    <meta name="description" content={`Make your own ${this.state.name} Discord bot for free in 5 minutes with no coding required. Choose from over 300 commands to enable Moderation, Utilities, Economy and more`}/>
                <meta name="keywords" content={this.state.tags}/>
                </Helmet>
                <div className="home-container">
                    <NewHomeNav />

                    <div className="home-header-content">
                        <div className="home-header-container">
                            <div style={{ width: "45%" }} className="home-header-title">
                                <div>
                                    <h1>Create your own {this.state.name} Discord Bot</h1>
                                    <p>Make your own {this.state.name} Discord bot for free in 5 minutes with no coding required. Choose from over 300 commands to enable Moderation, Utilities, Economy and more</p>
                                    <div className="header-buttons">
                                        <a className="btn btn-red mr-5" style={{ marginRight: "15px" }} href="https://dashboard.botghost.com/dashboard/">Get Started</a>
                                        <a className="btn btn-gray" href="#modules">Explore</a>
                                    </div>

                                </div>
                            </div>

                            <div className="home-header-modules" style={{ width: "50%", height: "100%", marginLeft: "auto" }}>
                                <div className="row justify-content-center">
                                    {this.renderModules()}

                                
                                </div>


                            </div>
                        </div>

                    </div>


                </div>

                <div class="wave">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
                    </svg>
                </div>
                <div className="home-stats-banner">
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="stat-box">
                                <div>
                                    <h3>723,232</h3>
                                    <p>Bots Created</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4">
                            <div className="stat-box">
                                <div>
                                    <h3>6,324,232</h3>
                                    <p>Active Users</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4">
                            <div className="stat-box">
                                <div>
                                    <h3>7,232,232</h3>
                                    <p>Discord Servers</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="wave">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                    </svg>
                </div>


                <div className="home-info-section home-info-ordering">
                    {this.renderSections()}
                    <NewModuleDefault/>
                </div>

                <div class="wave">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                         <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
                </svg>
            </div>
                <div id="modules" className="home-stats-banner"> 
                    <div className="home-botlist-section">
                        <h2>Use and Customize unique commands</h2>

                        <div className="row">
                            <div className="col-xl-3">

                            </div>
                        </div>
                    </div>
                </div>

                <div class="wave">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                    </svg>
                </div>

                {/* <div className="home-info-section home-info-ordering">
                    <div>
                        <div className="row">
                            {this.renderCommands()}
                        </div>
                    </div>

                </div> */}

            </main>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default NewModule
