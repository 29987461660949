import React, { Component } from 'react';
import { connect } from 'react-redux';
import ToolContainer from './ToolContainer';
import { Link } from "react-router-dom";


var FREE_TOOLS = [
    {
        name: "User ID Lookup",
        description: "Lookup information about a User using their unique Discord User ID.",
        image: process.env.PUBLIC_URL + "/img/icons/BotGhost_ID.png",
        link: "/tools/discord-user-id-lookup"
    },
    {
        name: "Discord Server Invite Checker",
        description: "Lookup information about a Discord Server invite.",
        image: process.env.PUBLIC_URL + "/img/icons/BotGhost_ID.png",
        link: "/tools/discord-invite-checker"
    },
    {
        name: "Permission Calculator",
        description: "Calculate the permissions integer for OAuth invite links by selecting individual permissions.",
        image: process.env.PUBLIC_URL + "/img/icons/BotGhost_Permission.png",
        link: "/tools/discord-permissions-calculator"
    },
    {
        name: "Time Converter",
        description: "Pick a time and date and automatically convert it to Discord's timestamp format. Times will then be displayed in everyone's local timezone.",
        image: process.env.PUBLIC_URL + "/img/icons/time_converter.png",
        link: "/tools/discord-time-converter"
    },
    {
        name: "Embed Builder",
        description: "Easily create and send Discord embeds and messages through webhooks..",
        image: process.env.PUBLIC_URL + "/img/icons/embedbuilder.png",
        link: "/tools/discord-embed-builder"
    },
    {
        name: "Vanity URL Checker",
        description: "Check if a Discord vanity URL is available for use or already taken.",
        image: process.env.PUBLIC_URL + "/img/icons/BotGhost_ID.png",
        link: "/tools/discord-vanity-checker"
    },
    {
        name: "Hex to Decimal Converter",
        description: "Convert hexadecimal numbers to decimal and binary representations.",
        image: process.env.PUBLIC_URL + "/img/icons/hex_to_decimal.png", // You'll need to add this icon
        link: "/tools/hex-to-decimal"
    }
];

export class Tools extends Component {

    renderTools = () => {
        var tools = [];

        FREE_TOOLS.forEach(tool => {
            tools.push(
                <Link to={tool.link}>
                    <div className='flex flex-col justify-center min-h-[300px] h-[300px] bg-[#2f3136] px-4 py-5 rounded-md hover:cursor-pointer hover:opacity-70'>
                        <img className='h-[90px] w-[90px]' src={tool.image}></img>
                        <h2 className='text-white text-2xl font-bold mt-4'>{tool.name}</h2>
                        <p className='muted-text mt-2'>{tool.description}</p>
                    </div>
                </Link>
            );
        });

        return tools;
    };
    render() {
        return (
            <ToolContainer title="Free Discord Tools" description="Free discord tools">
                <div className=''>
                    <div className="text-center text-white flex justify-center flex-col">
                        <img className='h-[60px] mb-3' src={process.env.PUBLIC_URL + '/img/logo-red.png'}></img>
                        <h1 className='text-5xl font-bold'>Free and Popular Discord Tools</h1>
                        <p className='muted-text'>Lookup information about a User using their unique Discord User ID.</p>
                    </div>



                    <div className='grid gap-y-6 md:grid-cols-2 lg:grid-cols-4 gap-x-6 mt-5'>
                        {this.renderTools()}

                    </div>

                </div>
            </ToolContainer>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default Tools;
