import React, { Component } from 'react'
import { connect } from 'react-redux'

export class BlogListItem extends Component {

    renderTags = () =>{
        var tags = [];
        this.props.tags.forEach(tag =>{
            tags.push(
                <span>{tag}</span>
            )
        })
        return tags;
    }
    render() {
        return (
            <div className="blog-item">
                <a href={`/blog/${this.props.slug}`}>
                <h2>{this.props.title}</h2>
                </a>
                <p>
                    {this.props.description}
                </p>

                <div className="blog-item-tags">
                    {this.renderTags()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default BlogListItem
