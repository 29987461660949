import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Aside } from './Aside'
import { Categories } from './Categories'
import { Main } from './Main'
import { Nav } from './Nav'
import qs from "qs"
import server from '../../api/server'
import { Title } from './Title'
import { MarketItem } from './MarketItem'


export class MarketSearch extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             query:"",
             bot_array:[]
        }
    }
    
    componentDidMount() {
        // ReactGA.pageview(window.location.pathname)
          const params = qs.parse(this.props.location.search);
          if(params['?query'] != undefined){
            var query = params['?query']
          }
          this.setState({query:query})
          this.getSearchResults(query)
      }

      getSearchResults = async (query)=>{
        const response = await server.post("/search",{query:query});
        if(response.data){
          this.setState({bot_array:response.data.bot_array,query});
        }
      }


      renderBots = () =>{
        var bots = []
        this.state.bot_array.forEach(bot =>{
            bots.push(<MarketItem img = {bot.img} name={bot.bot_name} short_description = {bot.short_description} stats={bot.stats} id={bot.bot_id}/>)
        })

        return bots;
    }


    render() {
        return (
            <div>
                    <Helmet>
                        <title>Search | BotGhost Marketplace</title>
                        <link rel="stylesheet" type="text/css" href="/css/market.css"/>
                    </Helmet>
                    <Nav/>

                    
                    <Title header="Search Results" description=""/>

                    <div className="market-grid">
                        {this.renderBots()}

                    </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default MarketSearch
