import React, { Component } from 'react'
import { connect } from 'react-redux'

const AllModules = [
	{
		name: "Server Management",
		modules: [
			{
				name: "Moderation",
				description: "Moderate your server with a set of powerful commands",
				icon: "moderation.png",
				premium: false
			},
			{
				name: "Announcements",
				description: "Create automatic announcements for unique events in your server!",
				icon: "announcements.png",
				premium: false
			},
			{
				name: "Automod",
				description: "Automatically moderate your servers text channels. No humans required.",
				icon: "automoderator.png",
				premium: false
			},
			{
				name: "Custom Commands",
				description: "Create custom commands to personalize your bot!",
				icon: "customcommands.png",
				premium: false
			},
			{
				name: "Auto-Role",
				description: "Automatically assign roles to users when they join your server.",
				icon: "autorole.png",
				premium: false
			},
			// {
			// 	name: "Usage",
			// 	description: "Change how your bot operates and how members use your bot.",
			// 	icon: "operation.png",
			// 	premium: false
			// },
			{
				name: "Timed Messages",
				description: "Automatically send timed messages to your server.",
				icon: "messenger.png",
				premium: false
			},
			// {
			// 	name: "Status",
			// 	description: "Replace the 'Created on BotGhost.com' status with your own.",
			// 	icon: "wrench.png",
			// 	premium: true
			// },
			{
				name: "Reaction Roles",
				description: "Setup a Reaction Role system",
				icon: "reactroles.svg",
				premium: true
			},
		]
	},
	{
		name: "Social Integrations",
		modules: [
			{
				name: "YouTube",
				description: "Receive notifications when you or someone else posts a new video",
				icon: "youtube.png",
				premium: true
			},
			{
				name: "Twitter",
				description: "Have new tweets automatically posted in your server.",
				icon: "twitter.png",
				premium: true
			},
			{
				name: "Reddit",
				description: "Get new post alerts directly in your discord server.",
				icon: "reddit.png",
				premium: true
			},
			{
				name: "Twitch",
				description: "Setup stream notifications so your members never miss when you go online.",
				icon: "twitch.png",
				premium: true
			},
		]
	},
	{
		name: "Fun",
		modules: [
			{
				name: "Meme Creator",
				description: "Create memes using templates from ImgFlip.",
				icon: "memecreator.webp",
				premium: false
			},
			{
				name: "Levels",
				description: "Create a leveling system and hosted leaderboard for your server.",
				icon: "levels.png",
				premium: false
			},
			{
				name: "Music",
				description: "Listen to music directly in your discord server.",
				icon: "music.png",
				premium: true
			},
			{
				name: "Economy",
				description: "Set up a fully fledged server economy",
				icon: "economy.png",
				premium: true
			},
			{
				name: "Trivia",
				description: "Test your wits against fellow members",
				icon: "trivia.png",
				premium: true
			},
		]
	},
	{
		name: "Utilities",
		modules: [
			{
				name: "Search",
				description: "Search the Internet for a variety of things",
				icon: "search.png",
				premium: false
			},
			{
				name: "Weather",
				description: "get the weather before heading outside",
				icon: "weather.png",
				premium: true
			},
			{
				name: "Translator",
				description: "Translate messages with built in Google Translator",
				icon: "translator.png",
				premium: true
			},
			// {
			// 	name: "Co Create",
			// 	description: "Invite other people to edit and work on your bot.",
			// 	icon: "co-create.png",
			// 	premium: true
			// },
			// {
			// 	name: "Botlist",
			// 	description: "Advertise your bot on BotGhost",
			// 	icon: "botlist.png",
			// 	premium: true
			// },
		]
	},
	{
		name: "Game Integrations",
		modules: [
			{
				name: "Fortnite",
				description: "Get Fortnite stats directly from Fortnite Tracker",
				icon: "fortnite.png",
				premium: true
			},
			{
				name: "Apex Legends",
				description: "Share your latest stats directly from Apex Tracker",
				icon: "apex.png",
				premium: true
			},
		]
	}
]



export class Categories extends Component {

    renderModules = () =>{
        const categories = []
        AllModules.forEach(category =>{
            const categoryModules = []
            category.modules.forEach(categoryModule =>{
                categories.push(
                    <a className="category" href={`/marketplace/search?query=${categoryModule.name}`}>
                        <img class="" src={`/img/icons/${categoryModule.icon}`}/>
                        <h3>{categoryModule.name}</h3>
                    </a>
                    // <div className="col-xl-2 col-lg-2 col-sm-2 col-3">
                    //     <a href="#modules">
                    //         <img class="home-module-icon" src={`/img/icons/${categoryModule.icon}`}/>
    
                    //     </a>
                    // </div>
    
                )
            })
    
        })
    
        return categories
    }

    

    render() {
        return (
            <div className="categories">
                {this.renderModules()}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(Categories)
