import React, { Component } from 'react';
import { connect } from 'react-redux';
import './preview.css';
import moment from 'moment';
import MarkdownRender from './MarkdownRender';


const MAX_FIELDS_PER_ROW = 3;
const FIELD_GRID_SIZE = 12;

export class DiscordEmbedPreview extends Component {
    // inline formatting
    getFieldGridColumn = (fields, fieldIndex) => {
        const field = fields[fieldIndex];

        if (!field.inline) {
            return `1 / ${FIELD_GRID_SIZE + 1}`;
        }

        let startingField = fieldIndex;
        while (startingField > 0 && fields[startingField - 1].inline) {
            startingField -= 1;
        }

        let totalInlineFields = 0;
        while (fields.length > startingField + totalInlineFields && fields[startingField + totalInlineFields].inline) {
            totalInlineFields += 1;
        }

        const indexInSequence = fieldIndex - startingField;
        const currentRow = indexInSequence / MAX_FIELDS_PER_ROW;
        const indexOnRow = indexInSequence % MAX_FIELDS_PER_ROW;
        const totalOnLastRow = totalInlineFields % MAX_FIELDS_PER_ROW || MAX_FIELDS_PER_ROW;
        const fullRows = (totalInlineFields - totalOnLastRow) / MAX_FIELDS_PER_ROW;
        const totalOnRow = currentRow >= fullRows ? totalOnLastRow : MAX_FIELDS_PER_ROW;

        const columnSpan = FIELD_GRID_SIZE / totalOnRow;
        const start = indexOnRow * columnSpan + 1;
        const end = start + columnSpan;

        return `${start} / ${end}`;
    };

    renderEmbeds = () => {
        var embeds = [];


        this.props.embeds.forEach(embed => {

            var fields = [];

            embed.fields.forEach((field, index) => {
                fields.push(
                    <div className="discord-embed-field" key={field.id} style={{ gridColumn: this.getFieldGridColumn(embed.fields, index) }}>
                        <div className="discord-embed-field-title"><b>{field.name}</b></div>
                        <div className="discord-embed-field-content"><MarkdownRender text={field.value}></MarkdownRender></div>
                    </div>
                );
            });
            embeds.push(
                <div className="discord-embed">
                    {embed.color ? <div class="discord-embed-left-border" style={{ backgroundColor: embed.color || "#000000" }}></div> : <div class="discord-embed-left-border" style={{ backgroundColor: "#000000" }}></div>}
                    <div className="discord-embed-container" style={{ width: "100%" }}>
                        <div className="discord-embed-content">
                            <div>
                                {(embed.author.icon_url || embed.author.name) && <div class="discord-embed-author">
                                    {embed.author.icon_url && <img src={embed.author.icon_url} />}
                                    {embed.author.name && <a href={embed.author.url} rel="noopener noreferrer">{embed.author.name}</a>}
                                </div>}

                                {embed.title && <div class="discord-embed-title">
                                    {embed.title && <a href={embed.url} className={embed.url && '!text-[#00b0f4]'} target="_blank" rel="noopener noreferrer">{embed.title}</a>}
                                </div>}

                                {embed.description && <div class="discord-embed-description"><p><MarkdownRender text={embed.description}></MarkdownRender></p></div>}

                                <div className="discord-embed-fields" style={{
                                    minWidth: 0,
                                    margin: '8px 0px, 0px',
                                    display: 'grid',
                                    gridColumn: 1 / 2,
                                    gridGap: 8,
                                }}>
                                    {fields}
                                </div>

                                {(embed.image.url && embed.image.url !== "") && <img class="discord-embed-image" src={embed.image.url} alt=""></img>}
                            </div>
                            {(embed.thumbnail.url && embed.thumbnail.url !== "") && <img class="discord-embed-thumbnail" src={embed.thumbnail.url} alt="" />}
                        </div>

                        {(embed.footer.icon_url || embed.footer.text || embed.timestamp) && <div class="discord-embed-footer">
                            {embed.footer.icon_url && <img src={embed.footer.icon_url} />}
                            {embed.footer.text && <span>{embed.footer.text}</span>}
                            {embed.timestamp && <span>•  {moment(embed.timestamp).format("MM/DD/YY hh:MM")}</span>}
                        </div>}
                    </div>
                </div>
            );
        });


        return embeds;
    };
    render() {
        return (
            <div className="discord-preview-container">
                <div className="discord-preview">
                    <div className="discord-messages">
                        <div className="discord-message">



                            <div className="discord-message-content">
                                <div class="discord-author-avatar">
                                    <img src={this.props.avatarURL || "http://localhost:3000/img/logo-red.png"} alt="" />

                                </div>

                                <div class="discord-message-body">
                                    <div className="discord-author-info-block">
                                        <span class="discord-author-info">
                                            <span class="discord-author-username">{this.props.username || "BotGhost"}</span>
                                            <span class="discord-author-bot-tag"> Bot </span>
                                        </span>
                                        <span class="discord-message-timestamp">Today at {moment().format("h:MM A")}</span>
                                    </div>
                                    <MarkdownRender text={this.props.content} />


                                    {this.renderEmbeds()}
                                </div>
                            </div>




                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default DiscordEmbedPreview;