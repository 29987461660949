import { faDownload, faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { connect } from 'react-redux'

export class CommandItem extends Component {

    renderTags = () =>{
        var tags = []
        this.props.command.tags.forEach(tag =>{
            tags.push(
                <a href="#">#{tag}</a>
            )
        })
        return tags;
    }

    nFormatter = (num, digits) => {
        const lookup = [
          { value: 1, symbol: "" },
          { value: 1e3, symbol: "k" },
          { value: 1e6, symbol: "M" },
          { value: 1e9, symbol: "G" },
          { value: 1e12, symbol: "T" },
          { value: 1e15, symbol: "P" },
          { value: 1e18, symbol: "E" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup.slice().reverse().find(function(item) {
          return num >= item.value;
        });
        return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
      }
    render() {
        return (
            <div className='command-item'>
                <img src={this.props.command.img}></img>
                <div className='command-info'>
                    <h3>/{this.props.command.name}</h3>
                    <span>{this.props.command.description}</span>
                    <div className='command-footer'>
                        <div className='command-updated'>Updated 6 Hours Ago</div>
                        <div className='command-tags'>
                            {this.renderTags()}
                        </div>
                    </div>
                </div>

                <div className='command-stats'>
                    <div className='command-stat'><span><FontAwesomeIcon icon={faEye}/> {this.nFormatter({num:12312512,digits:1})} views</span></div>
                    <div className='command-stat'><span><FontAwesomeIcon icon={faDownload}/> 12.5k imports</span></div>

                </div>

            </div>
        )
    }
}



const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}



export default CommandItem