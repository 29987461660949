import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Link } from "react-router-dom";
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export class Nav extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false
    };
  }

  signUpClick = () => {
    console.log(window.location.pathname);
    ReactGA.event({
      category: "Sign Up",
      label: window.location.pathname,
      action: "Blog Posts"
    });
  };

  showMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
  };


  render() {
    if (this.props.new == true) {
      return (
        <div className={`${this.state.menuOpen == true ? "mobile-open" : null}`}>
          <header className="dash-header">

            <div className="header-item">
              <div className="header-logo">
                <img src={process.env.PUBLIC_URL + '/img/logo-red.png'}></img>
              </div>
            </div>

            <div className="header-item non-mobile">
              <nav className="dash-header-menu">
                <ul>
                  <li><Link to="/">Home</Link></li>
                  <li><Link to="/features">Features</Link></li>
                  <li><Link to="/blog/">Blog</Link></li>
                  <li><a href="mailto:fabian@botghost.com">Partner Program</a></li>
                  <li><a href="https://twitter.com/GetBotGhost">Updates</a></li>
                  <li><a href="https://docs.botghost.com">Documentation</a></li>
                </ul>
              </nav>
            </div>

            <div className="header-item header-item-right">
              <nav className="dash-header-menu-right">
                <ul>
                  <li className="non-mobile" onClick={(e) => { this.signUpClick(); }}><a onClick={(e) => { this.signUpClick(); }} href="https://dashboard.botghost.com/dashboard" target="_blank">Log in</a></li>
                  <li className="mr-2" onClick={(e) => { this.signUpClick(); }}><a onClick={(e) => { this.signUpClick(); }} href="https://dashboard.botghost.com/dashboard" style={{ padding: "5px 25px" }} target="_blank" className="btn btn-red sign-up-button" style={{ fontWeight: "700" }}> Sign Up</a></li>
                  <li className="mobile-only"><span onClick={(e) => { this.showMenu(); }}><FontAwesomeIcon icon={faBars} size="2x" /></span></li>

                </ul>

              </nav>
            </div>

          </header>

          {this.state.menuOpen ?
            <div className="mobile-nav-dropdown">
              <nav>
                <ul>
                  <li><Link to="/">Home</Link></li>
                  <li><Link to="/features">Features</Link></li>
                  <li><Link to="/bots">Bots</Link></li>
                  <li><Link to="/blog/">Blog</Link></li>
                  <li><Link to="/partners">Partner Program</Link></li>
                  <li><a href="https://twitter.com/GetBotGhost">Updates</a></li>
                  <li><a href="https://docs.botghost.com">Documentation</a></li>
                </ul>
              </nav>
            </div> : null}
        </div>
      );
    } else {
      return (
        <header>
          <nav class="navbar navbar-expand-lg">
            <Link class="navbar-brand" to="#">
              <img src="/img/logo-new.png" width="40" height="40" alt="" />
            </Link>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"><i class="fas fa-bars"></i></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                  <Link class="nav-link" to="/">Home</Link>
                </li>
                <li class="nav-item dropdown">
                  <Link class="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Features
                  </Link>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <Link class="dropdown-item" to="/modules/moderation">Moderation</Link>
                    <Link class="dropdown-item" to="/modules/announcement">Announcements</Link>
                    <Link class="dropdown-item" to="/modules/auto-moderator">Auto-moderator</Link>
                    <Link class="dropdown-item" to="/modules/custom-commands">Custom Commands</Link>
                    <Link class="dropdown-item" to="/modules/economy">Economy</Link>
                    <Link class="dropdown-item" to="/modules/trivia">Trivia</Link>
                    <Link class="dropdown-item" to="/modules/weather">Weather</Link>
                    <Link class="dropdown-item" to="/modules/translator">Translator</Link>
                    <Link class="dropdown-item" to="/modules/youtube">YouTube</Link>
                    <Link class="dropdown-item" to="/modules/twitter">Twitter</Link>
                    <Link class="dropdown-item" to="/modules/reddit">Reddit</Link>
                    <Link class="dropdown-item" to="/modules/twitch">Twitch.tv</Link>
                    <Link class="dropdown-item" to="/modules/fortnite">Fortnite</Link>
                    <Link class="dropdown-item" to="/modules/apex">Apex Legends</Link>
                  </div>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/stats">Stats</Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/community/">Blog</Link>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="https://dashboard.botghost.com/dashboard">Dashboard</a>
                </li>

                <li class="nav-item">
                  <a class="nav-link" href="https://twitter.com/Tomtoise" target="_blank">Updates</a>
                </li>

                <li class="nav-item">
                  <a class="nav-link" href="https://docs.botghost.com" target="_blank">Documentation</a>
                </li>
              </ul>
              <div class="form-inline my-2 my-lg-0">
                <li class="nav-item">
                  <span class="leaderboard-cta"><a href="https://dashboard.botghost.com/dashboard" class="">Create a Free Discord Bot</a></span>
                </li>
              </div>
            </div>
          </nav>
        </header>
      );
    }

  }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {

};

export default Nav;
