import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Aside } from './Aside'
import { Main } from './Main'
import { Nav } from './Nav'

export class Market extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Market</title>
                    <link rel="stylesheet" type="text/css" href="/css/market.css"/>
                </Helmet>
                <Nav/>

                <main className="market-main">
                    {/* <Aside/> */}
                    <Main/>
                </main>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

// export default connect(mapStateToProps, mapDispatchToProps)(Market)
export default Market
