import React, { Component } from 'react'
import { connect } from 'react-redux'

export class ModuleCommand extends Component {
    render() {
        return (
            <div>
            <div
              className="command module-command"
            >
              <div
                className="command-info"

              >
                <h3>{this.props.name}
                </h3>
                <span>{this.props.description}</span>
              </div>
    
            </div>
          </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default ModuleCommand
