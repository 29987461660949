import React, { Component } from 'react'
import { connect } from 'react-redux'

export class Aside extends Component {
    render() {
        return (
            <div className="market-aside">
                
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(Aside)
