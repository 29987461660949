import React, { Component } from 'react'
import { connect } from 'react-redux'

export class NewModuleSection extends Component {
    render() {
        return (
            <section>
            <div className={`home-info-section-inner reverse-order ${this.props.odd ? "row-reverse":null}`}>

            <div className="home-info-part">
                    <div>
                        <img src={process.env.PUBLIC_URL + "/img/gifs/"+this.props.img}></img>
                    </div>

                </div>


                <div className="home-info-part">
                    <div>
                        <h2>{this.props.title}</h2>
                        <p>
                            {this.props.text}
                        </p>
                    </div>    
                </div>
            </div>
        </section>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default NewModuleSection
