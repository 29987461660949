import React, { Component } from 'react';
import { connect } from 'react-redux';

export class NotFound extends Component {
    render() {
        return (
            <div className='bg-darkGray w-[100vw] h-[100vh] flex justify-center flex-col text-white'>
                <h1 className='font-bold text-8xl'>404</h1>
                <p className='text-muted text-4xl'>Page not found :(</p>
                <a href="/" className='btn btn-red font-bold mt-3 px-5 py-2'>Go back home</a>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default NotFound;