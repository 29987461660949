import React, { Component } from 'react';
import { connect } from 'react-redux';
const parse = require('html-react-parser');

const bold = /\*\*(.*)\*\*/gim;
const italics = /\*(.*)\*/gim;
const link = /\[(.*?)\]\((.*?)\)/gim;
const lineBreak = /\n$/gim;
const underline = /\_\_(.*)\_\_/gim;

export class MarkdownRender extends Component {

    parseMarkdown = (markdownText) => {
        const htmlText = markdownText
            .replace(bold, '<b>$1</b>')
            .replace(italics, '<i>$1</i>')
            .replace(link, "<a href='$2' className='embed-link !text-[#00b0f4'>$1</a>")
            .replace(lineBreak, '<br />')
            .replace(underline, '<u>$1</u>');


        console.log(htmlText, 'HTML TEXT');
        return htmlText.trim();
    };
    render() {
        return (
            <>{parse(this.parseMarkdown(this.props.text))}</>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default MarkdownRender;