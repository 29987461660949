import React, { Component } from 'react';
import { connect } from 'react-redux';
import ToolContainer from './ToolContainer';
import server from '../../api/server';
import { LoaderV2 } from './Loader';

const FAQS = [
    {
        question: "What is a Discord vanity URL?",
        answer: "A Discord vanity URL is a custom URL that replaces the generic invite link for your server, making it easier for others to find and join."
    },
    {
        question: "How do I get a vanity URL for my Discord server?",
        answer: "To get a vanity URL, your server needs to be at level 3, which requires 14 server boosts. Once eligible, you can set a custom vanity URL in your server settings."
    },
    {
        question: "How do I check if a vanity URL is available?",
        answer: "You can use our Vanity URL Checker tool to check if a specific vanity URL is available or already taken by another server."
    },
    {
        question: "What are the benefits of having a vanity URL?",
        answer: "Vanity URLs are easier to remember, share, and promote. They also give your server a more professional and branded appearance."
    },
    {
        question: "Can I change my server's vanity URL after setting it?",
        answer: "Yes, you can change your server's vanity URL, but there may be a cooldown period between changes."
    },
    {
        question: "Are there any restrictions on vanity URL names?",
        answer: "Yes, vanity URLs must be between 3-32 characters long and can only contain letters, numbers, and hyphens."
    },
    {
        question: "Can two servers have the same vanity URL?",
        answer: "No, vanity URLs are unique across all of Discord. Once a URL is taken, it cannot be used by another server."
    },
    {
        question: "What happens if I lose server boosts and fall below level 3?",
        answer: "If your server falls below level 3, you'll lose access to the vanity URL feature, but your URL will be reserved for a certain period."
    },
    {
        question: "Can I use special characters in my vanity URL?",
        answer: "No, vanity URLs are limited to alphanumeric characters (A-Z, 0-9) and hyphens."
    },
    {
        question: "How long does it take for a vanity URL to become active?",
        answer: "Vanity URLs typically become active immediately after setting them in your server settings."
    },
    {
        question: "Can I reserve a vanity URL for future use?",
        answer: "No, you can't reserve vanity URLs. They are available on a first-come, first-served basis."
    },
    {
        question: "Will my old invite links still work after setting a vanity URL?",
        answer: "Yes, your previous invite links will continue to work alongside your new vanity URL."
    },
    {
        question: "Can I use my brand name as a vanity URL?",
        answer: "Yes, as long as it's available and complies with Discord's guidelines and terms of service."
    },
    {
        question: "What should I do if my desired vanity URL is taken?",
        answer: "Try variations of your desired URL or consider using keywords related to your server's theme."
    },
    {
        question: "Can vanity URLs contain spaces?",
        answer: "No, spaces are not allowed in vanity URLs. You can use hyphens instead to separate words."
    },
    {
        question: "Is there a limit to how many times I can change my vanity URL?",
        answer: "While there's no strict limit, frequent changes may be subject to rate limiting or cooldown periods."
    },
    {
        question: "Can I use someone else's trademark as my vanity URL?",
        answer: "It's not recommended, as it could potentially violate trademark rights and Discord's terms of service."
    },
    {
        question: "Do vanity URLs affect my server's discoverability?",
        answer: "While vanity URLs don't directly impact discoverability, they can make it easier for people to find and share your server."
    }
];

export class VanityChecker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            vanity: "",
            loaded: false,
            loading: false,
            error: null,
            result: null
        };
    }

    checkVanity = async () => {
        this.setState({
            loading: true,
            loaded: false,
            error: null
        });
        var vanity = this.state.vanity;

        var response = await server.get("/public/tools/vanity_check/" + vanity).catch(e => {
            this.setState({ error: "Could not check vanity URL", loading: false });
        });

        if (response && response.data) {
            this.setState({ result: response.data, loaded: true, loading: false });
        }
    };

    render() {
        return (
            <ToolContainer faqs={FAQS} title="Discord Vanity URL Checker" description="Check if a Discord vanity URL is available for use">
                <div className='lg:px-[25%]'>
                    <div className="text-center text-white flex justify-center flex-col mb-3">
                        <img className='h-[60px]' src={process.env.PUBLIC_URL + '/img/logo-red.png'}></img>
                        <h1 className='text-5xl font-bold'>Discord Vanity URL Checker</h1>
                        <p className='muted-text'>Check if a Discord vanity URL is available for use or already taken.</p>
                    </div>

                    <div className='mt-5'>
                        <div>
                            <label htmlFor="vanity" className="block text-md font-bold leading-6 text-white">
                                Vanity URL
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="vanity"
                                    id="vanity"
                                    value={this.state.vanity}
                                    onChange={(e) => this.setState({ vanity: e.target.value })}
                                    className="block w-full rounded-md border-0 py-2 px-2 text-lg text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    placeholder="Enter vanity URL"
                                />
                            </div>
                        </div>

                        <div>
                            <button className='btn btn-red mt-2' onClick={this.checkVanity}>
                                Check Availability
                            </button>
                        </div>
                    </div>

                    <div className='text-red mt-5 font-bold text-xl'>
                        {this.state.error}
                    </div>

                    {this.state.loaded && (
                        <div className='mt-5'>
                            <h2 className='text-4xl font-bold text-white mb-3'>Result</h2>
                            <div className='bg-[#2f3136] px-5 py-5 mt-3 rounded-md'>
                                <p className='text-white text-xl font-bold mb-4'>
                                    {this.state.result.available
                                        ? `The vanity URL "${this.state.vanity}" is available!`
                                        : `The vanity URL "${this.state.vanity}" is already taken.`}
                                </p>
                                {!this.state.result.available && this.state.result.guild && (
                                    <div className='mt-3'>
                                        <p className='text-white text-lg'>
                                            <span className='font-bold text-[#43b581]'>Server Name:</span> {this.state.result.guild.name}
                                        </p>
                                        <p className='text-white text-lg mt-2'>
                                            <span className='font-bold text-[#43b581]'>Description:</span> {this.state.result.guild.description || 'No description available'}
                                        </p>
                                        <p className='text-white text-lg mt-2'>
                                            <span className='font-bold text-[#43b581]'>Expiration:</span> {this.state.result.expires_at ? new Date(this.state.result.expires_at).toLocaleString() : 'Never'}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {this.state.loading && <LoaderV2 text="Checking Vanity URL" />}
                </div>
            </ToolContainer>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default VanityChecker;
