import React, { Component } from 'react';
import NewHomeNav from '../elements/NewHomeNav';
import { Helmet } from 'react-helmet';

export default class ToolContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            faqs: []
        };
    }

    componentDidMount() {
        if (this.props.faqs) {
            // Split it into 3 cols and set State
            var faqs = this.props.faqs;
            var faqs_split = [];
            var faqs_split_temp = [];
            var faqs_split_temp_index = 0;
            for (var i = 0; i < faqs.length; i++) {
                faqs_split_temp.push(faqs[i]);
                faqs_split_temp_index++;
                if (faqs_split_temp_index == 3) {
                    faqs_split.push(faqs_split_temp);
                    faqs_split_temp = [];
                    faqs_split_temp_index = 0;
                }
            }
            if (faqs_split_temp.length > 0) {
                faqs_split.push(faqs_split_temp);
            }
            this.setState({
                faqs: faqs_split
            });
        }
    }
    render() {
        return (
            <>
                <Helmet>
                    <title>{this.props.title} | BotGhost | Free Discord Bot Maker</title>
                    <meta name="description" content={this.props.description} />
                </Helmet>
                <NewHomeNav></NewHomeNav>

                <main className='min-h-[100vh] bg-darkGray pt-20 pb-20'>

                    <div className='container'>
                        {this.props.children}
                    </div>


                    {this.state.faqs.length > 0 ? <section
                        id="faq"
                        aria-labelledby="faq-title"
                        className="relative bg-darkGray py-5 pt-14 mt-0 overflow-hidden bg-slate-50 py-20 sm:py-32"
                    >
                        <div
                            className="absolute bg-red left-1/2 top-0 max-w-none -translate-y-1/4 translate-x-[-30%]"
                            alt=""
                            width={1558}
                            height={946}
                            unoptimized
                        />
                        <div className="relative">
                            <div className="mx-3 lg:!mx-64 mb-24 mt-24">
                                <div>
                                    <h2 className='font-display text-3xl font-bold tracking-tight text-white sm:text-4xl md:text-5xl'>Frequently asked questions</h2>
                                    {/* <p className='mt-6 text-lg tracking-tight text-white opacity-70'>{this.state.faqs_description}</p> */}

                                </div>
                            </div>

                            <div className=' mx-3 lg:!mx-64'>
                                <ul
                                    role="list"
                                    className="mx-auto container mt-16 text-white grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3"
                                >
                                    {this.state.faqs.map((column, columnIndex) => (
                                        <li key={columnIndex}>
                                            <ul role="list" className="flex flex-col gap-y-8">
                                                {column.map((faq, faqIndex) => (
                                                    <li key={faqIndex}>
                                                        <h3 className="font-display font-bold text-2xl leading-7 text-slate-900">
                                                            {faq.question}
                                                        </h3>
                                                        <p className="mt-4 text-md opacity-70">{faq.answer}</p>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </section> : null}

                </main>
            </>
        );
    }
}
