import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faGripVertical, faHammer, faLink, faMousePointer, faQuestion, faRandom, faReply, faSlash, faSort, faTextHeight, faTimes } from '@fortawesome/free-solid-svg-icons';
import ReactFlow, {
  Background, ReactFlowProvider,
  ReactFlowProps,
  addEdge,
  removeElements,
  Handle,
  isEdge,
  ControlButton,
  isNode,
  useZoomPanHelper,
  getBezierPath,
  getMarkerEnd,
  getEdgeCenter,
  Controls,
  getIncomers,
  useNodesState,
  useEdgesState,
  getOutgoers,
  applyNodeChanges,
} from 'react-flow-renderer';


export class HomeCommandBuilder extends Component {

  constructor(props) {
    super(props);

    this.state = {
      nodes: [
        {
          id: 'root',
          type: 'root',
          data: {
            title: "Buttons",
            description: 'Description',
            data: {

            }
          },
          // target:"3",
          draggable: false,
          position: { x: 50, y: 107 },
        },
        {
          "id": "option_2",
          "type": "option",
          "position": {
            "x": 267,
            "y": -25
          },
          draggable: true,
          "data": {
            "node_options": {
              "title": "Buttons",
              "description": "A plain text option",
              icon: faTextHeight
            },
            "data": {
              "type": "string",
              "name": "User",
              "description": "User Option",
              "required": false,
              "validated": true
            }
          }
        },
        {
          "id": "action_4",
          "type": "action",
          "position": {
            x: 145,
            y: 300
          },
          draggable: true,
          "data": {
            "node_options": {
              "title": "Send a Message with attached Buttons",
              "description": "Add buttons and button actions",
              "icon": faMousePointer
            },
          }
        },

        {
          "id": "plaintextaction",
          "type": "action",
          "position": {
            x: 3,
            y: 558
          },
          "data": {
            "node_options": {
              "title": "Plain Text Reply",
              "description": "Bot replies with a plain text response",
              "icon": faReply
            },
          }
        },

        {
          "id": "roleaction",
          "type": "action",
          "position": {
            x: 390,
            y: 558
          },
          "data": {
            "node_options": {
              "title": "Add Roles",
              "description": "Adds one or more roles to a user",
              "icon": faReply
            },
          }
        },

        {
          "id": "button1",
          "type": "button",
          "data": {
            data: {
              label: "👋 Hey",
              style: "PRIMARY"
            }
          },
          "draggable": true,
          "position": {
            x: 127,
            y: 429
          },
          "targetPosition": "top",
          "sourcePosition": "bottom"
        },

        {
          "id": "button2",
          "type": "button",
          "data": {
            data: {
              label: "Click Me",
              style: "DANGER"
            }
          },
          "draggable": true,
          "position": {
            x: 495,
            y: 429
          },
          "targetPosition": "top",
          "sourcePosition": "bottom"
        },

      ],
      edges: [
        {
          source: "option_2",
          target: "root",
          type: "step",
          animated: true,
          arrowHeadType: "arrowclosed"
        },

        {
          source: "action_4",
          target: "button1",
          type: "step",
          animated: true,
          arrowHeadType: "arrowclosed"
        },
        {
          source: "action_4",
          target: "button2",
          type: "step",
          animated: true,
          arrowHeadType: "arrowclosed"
        },
        {
          "source": "root",
          "sourceHandle": "action",
          "target": "action_4",
          "targetHandle": "action",
          "animated": true,
          "arrowHeadType": "arrowclosed",
          "type": "custom",
          "id": "edge1"
        },
        {
          "source": "button1",
          "sourceHandle": "action",
          "target": "plaintextaction",
          "targetHandle": "action",
          "animated": true,
          "arrowHeadType": "arrowclosed",
          "type": "custom",
          "id": "edge2"
        },
        {
          "source": "button2",
          "sourceHandle": "action",
          "target": "roleaction",
          "targetHandle": "action",
          "animated": true,
          "arrowHeadType": "arrowclosed",
          "type": "custom",
          "id": "edge3"
        }
      ]
    };
  }

  componentDidMount() {
    var nodes = [...this.state.nodes];
    var width = window.innerWidth;
    var rootWidth = 540;
    var nodeWidth = width - rootWidth - (width * 0.1);
    console.log(width);
    nodes.forEach(node => {
      node.position.x += nodeWidth;
      node.position.y += 100;
    });

    this.setState({ nodes: nodes });
  }


  RootNode = ({ id, data }) => (
    <>
      <Handle style={{ backgroundColor: "#8d0eab", border: "none", height: "20px", width: "20px", top: "-8px" }} type="target" position="top" isValidConnection={(connection) => connection.target === "root"} />
      <div id={id} onClick={(e) => { }} className={`action-node root-action-node ${this.props.selected == id ? "selected" : null}`} style={{ width: "600px" }}>

        <div className="inner" style={{ display: "flex" }}>


          <div className="object-icon object-icon-root">
            <FontAwesomeIcon icon={faHammer} />
          </div>

          <div className="object-info object-info-action">
            <span className="object-title" style={{ fontSize: "30px", lineHeight: "30px" }}>/{data.title}</span>
            <span className="object-description" style={{ fontSize: "20px", lineHeight: "20px" }}>Drag and drop different <span style={{ color: "rgb(141, 14, 171)" }}>options</span>, <span style={{ color: "rgb(53, 141, 235)" }}>actions</span> and <span style={{ color: "#28a745" }}>conditions</span> to add them to your command. Connect the corresponding colors to create your command.</span>
          </div>
        </div>
      </div>
      <Handle id="action" type="source" style={{ backgroundColor: "#358deb", border: "none", height: "20px", width: "20px", bottom: "-8px" }} position="bottom" />
    </>
  );


  OptionNode = ({ id, data }) => (
    // <>
    //     <div onClick={(e) =>{}} className={`custom-node option-node ${this.props.selected == id ? "selected":null}`}>
    //       <span className="title">{data.title} Option</span>
    //       <span className="description">{data.description}</span>
    //   </div>
    //   <Handle type="source" position="bottom" isValidConnection = {(connection) => connection.target === "root"} />
    // </>

    <>
      <div id={id} onClick={(e) => { }} style={{ position: "relative" }} className={`action-node option-node ${this.props.selected == id ? "selected" : null}`} >

        <div className="inner" style={{ display: "flex" }}>

          {data.data == undefined || data.data.validated == false ?
            <div className="node-invalid">
              <FontAwesomeIcon icon={faExclamation} />
            </div> : null}



          <div className="object-icon object-icon-option">
            <FontAwesomeIcon icon={data.node_options.icon} />
          </div>

          <div className="object-info object-info-option">
            <span className="object-title">{data.data != undefined && data.data.name != "" ? data.data.name : data.node_options.title}</span>
            <span className="object-description">{data.data != undefined && data.data.description != "" ? data.data.description : data.node_options.description}</span>
          </div>
        </div>
      </div>
      <Handle type="source" position="bottom" style={{ backgroundColor: "#8d0eab", border: "none", height: "10px", width: "10px" }} />
    </>
  );

  ActionNode = ({ id, data }) => (
    // <>
    //   <Handle id={"action"} type="target" position="top" isValidConnection = {this.actionConnection} />
    //     <div onClick={(e) =>{}} className={`custom-node action-node ${this.props.selected == id ? "selected":null}`}>
    //       <span className="title">{data.title}</span>
    //       <span className="description">{data.description}</span>
    //   </div>
    //   <Handle type="source" position="bottom"  />
    // </>

    <>
      <Handle className={`${this.state.handleId == "action" ? "compatible-node" : null}`} style={{ backgroundColor: "#358deb", border: "none", top: `${this.state.handleId == "action" ? "-8px" : "-4px"}`, height: `${this.state.handleId == "action" ? "20px" : "10px"}`, width: `${this.state.handleId == "action" ? "20px" : "10px"}` }} id={"action"} type="target" position="top" isValidConnection={this.actionConnection} />

      <div id={id} onClick={(e) => { }} className={`action-node option-node ${this.props.selected == id ? "selected" : null}`}>

        <div className="inner" style={{ display: "flex" }}>

          <div className="object-icon object-icon-action">
            <FontAwesomeIcon icon={data.node_options.icon} />
          </div>

          <div className="object-info object-info-action">
            <span className="object-title">{data.node_options.title}</span>
            <span className="object-description">{data.node_options.description}</span>
          </div>
        </div>
      </div>
      {/* <Handle  style={{backgroundColor:"#358deb",height:"10px",width:"10px",border:"none",}} type="source" position="bottom"  /> */}

      {data.node_options.title == "Send a Message with attached Buttons" ?

        <Handle isValidConnection={this.actionConnection} style={{ backgroundColor: "#358deb", height: "10px", width: "10px", border: "none", }} id="button" type="source" position="bottom" />
        :
        <Handle id="action" isValidConnection={this.actionConnection} style={{ backgroundColor: "#358deb", height: "10px", width: "10px", border: "none", }} type="source" position="bottom" />

      }
    </>
  );

  CustomEdge = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    data,
    arrowHeadType,
    markerEndId,
  }) => {
    const edgePath = getBezierPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
    });
    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
    const [edgeCenterX, edgeCenterY] = getEdgeCenter({
      sourceX,
      sourceY,
      targetX,
      targetY,
    });

    const foreignObjectSize = 40;

    return (
      <>
        <path
          id={id}
          style={style}
          className="react-flow__edge-path"
          d={edgePath}
          markerEnd={markerEnd}
        />
        <foreignObject
          width={foreignObjectSize}
          height={foreignObjectSize}
          x={edgeCenterX - foreignObjectSize / 2}
          y={edgeCenterY - foreignObjectSize / 2}
          className="edgebutton-foreignobject"
          requiredExtensions="http://www.w3.org/1999/xhtml"
        >
          <div className='edgebutton-container'>
            <button
              className="edgebutton"
              onClick={(event) => this.onEdgeClick(event, id)}
            >
              ×
            </button>
          </div>
        </foreignObject>
      </>
    );
  };

  ButtonNode = ({ id, data }) => (
    <>
      <Handle style={{ backgroundColor: "#358deb", border: "none", height: "10px", width: "10px" }} id={"button"} type="target" position="top" />
      <div id={id} onClick={(e) => { }} className={`button-node discord-button-${data.data.style.toLowerCase()} ${this.props.selected == id ? "selected" : null}`}>
        <div className="inner">
          <span>{data.data.label}</span>
        </div>
      </div>
      {data.data.style != "LINK" ?
        <Handle id="action" style={{ backgroundColor: "#358deb", height: "10px", width: "10px", border: "none", }} type="source" position="bottom" />
        : null}
    </>
  );

  nodeTypes = {
    option: this.OptionNode,
    root: this.RootNode,
    action: this.ActionNode,
    button: this.ButtonNode,
  };

  render() {
    return (
      <ReactFlowProvider>
        <div className='home-command-builder-container'>
          <div className='home-command-builder-header home-builder-content'>
            <div className='home-builder-inner' style={{ paddingLeft: "30px" }}>
              <h1 className="mb-2">Create your own commands with no coding required</h1>
              <p>Make your own Discord bot for free with no coding required. Create your own button, select menu and response commands or choose from premade modules.</p>
              <div className="header-buttons mt-2">
                <a className="btn btn-red mr-5" style={{ marginRight: "15px" }} href="https://dashboard.botghost.com/dashboard/">Create a bot</a>
                <a className="btn btn-gray" href="#modules">Modules</a>

              </div>
            </div>
          </div>
          <ReactFlow
            // onDragOver={this.onDragOver}
            // onConnect={this.onConnect}
            // onDrop={this.onDrop}
            // onLoad={this.onLoad}
            // onConnectStart={this.onConnectStart}
            // onConnectEnd={this.onConnectEnd}
            defaultZoom={0.9}
            onNodesChange={(changes) => {
              var newNodes = applyNodeChanges(changes, this.state.nodes);
              this.setState({ nodes: newNodes });

            }}
            onEdgesChange={(changes) => {
              console.log(changes);
            }}
            // snapToGrid={true}
            onNodeDragStop={(event, node) => {
              console.log(event, node);
            }}
            // snapGrid={[10, 10]}
            nodeTypes={this.nodeTypes}
            edgeTypes={this.edgeTypes}
            preventScrolling={false}
            zoomOnScroll={false}
            edges={this.state.edges}
            nodes={this.state.nodes}>


            <Background
              variant="dots"
              gap={20}
              size={0.5}
            />
            <Controls
              showZoom={false}
              showFitView={true}
              showInteractive={false}
            >
            </Controls>
          </ReactFlow>
        </div>
      </ReactFlowProvider >

    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default HomeCommandBuilder;