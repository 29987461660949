import React, { Component } from 'react';
import { connect } from 'react-redux';
import ToolInput from './ToolInput';
import ToolTextArea from './ToolTextArea';
import { Switch } from '@headlessui/react';
import { ChevronDownIcon, ChevronRightIcon, ClipboardDocumentIcon, TrashIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { BlockPicker, ChromePicker, SketchPicker } from 'react-color';
import DateTimePicker from 'react-datetime-picker';
import moment from "moment";
function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const imageReg = /((?:https?\:\/\/|www\.)[-a-zA-Z0-9@:%_\+.~#?&//=]+)\.(jpg|jpeg|gif|png|bmp|tiff|tga|svg|webp)/;
const urlReg = /((?:https?\:\/\/|www\.)(?:[-a-z0-9]+\.)*[-a-z0-9]+.*)/i;

export class DiscordEmbed extends Component {

    constructor(props) {
        super(props);

        this.state = {
            colorOpen: false,
            valid: false,
            errors: {
                descriptionRequired: {
                    message: "Description is required when a title is not set.",
                    error: true
                },
                url: {
                    message: "Invalid URL",
                    error: false
                },
                authorUrl: {
                    message: "Invalid URL",
                    error: false
                },
                authorIconUrl: {
                    message: "Invalid Image URL",
                    error: false
                },
                imageUrl: {
                    message: "Invalid Image URL",
                    error: false
                },
                thumbnailUrl: {
                    message: "Invalid Image URL",
                    error: false
                },
                footerIconUrl: {
                    message: "Invalid Image URL",
                    error: false
                },

            }
        };

        this.wrapperRef = React.createRef();
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.embed !== this.props.embed) {
            this.validateEmbed();
        }
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ colorOpen: false });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    validateEmbed = () => {
        var errors = { ...this.state.errors };
        var valid = true;
        if (!this.props.embed.description && !this.props.embed.title) {
            errors.descriptionRequired.error = true;
            valid = false;
        } else {
            errors.descriptionRequired.error = false;
        }


        // URL Validation
        if (this.props.embed.url) {
            if (!urlReg.test(this.props.embed.url)) {
                errors.url.error = true;
                errors.url.message = "Invalid URL";
                valid = false;
            } else {
                errors.url.error = false;
            }
        } else {
            errors.url.error = false;
        }

        // Author URL Validation
        if (this.props.embed.author?.url) {
            if (!urlReg.test(this.props.embed.author?.url)) {
                errors.authorUrl.error = true;
                errors.authorUrl.message = "Invalid URL";
                valid = false;
            } else {
                errors.authorUrl.error = false;
            }
        } else {
            errors.authorUrl.error = false;
        }

        // Author Icon URL Validation
        if (this.props.embed.author?.icon_url) {
            if (!imageReg.test(this.props.embed.author?.icon_url)) {
                errors.authorIconUrl.error = true;
                errors.authorIconUrl.message = "Invalid Image URL";
                valid = false;
            } else {
                errors.authorIconUrl.error = false;
            }
        } else {
            errors.authorIconUrl.error = false;
        }

        // Image URL Validation
        if (this.props.embed.image?.url) {
            if (!imageReg.test(this.props.embed.image?.url)) {
                errors.imageUrl.error = true;
                errors.imageUrl.message = "Invalid Image URL";
                valid = false;
            } else {
                errors.imageUrl.error = false;
            }
        } else {
            errors.imageUrl.error = false;
        }

        // Thumbnail URL Validation
        if (this.props.embed.thumbnail?.url) {
            if (!imageReg.test(this.props.embed.thumbnail?.url)) {
                errors.thumbnailUrl.error = true;
                errors.thumbnailUrl.message = "Invalid Image URL";
                valid = false;
            } else {
                errors.thumbnailUrl.error = false;
            }
        } else {
            errors.thumbnailUrl.error = false;
        }

        // Footer Icon URL Validation
        if (this.props.embed.footer?.icon_url) {
            if (!imageReg.test(this.props.embed.footer?.icon_url)) {
                errors.footerIconUrl.error = true;
                errors.footerIconUrl.message = "Invalid Image URL";
                valid = false;
            } else {
                errors.footerIconUrl.error = false;
            }
        } else {
            errors.footerIconUrl.error = false;
        }



        this.setState({ errors, valid });
    };

    updateData = (value, key) => {
        var embed = { ...this.props.embed };
        embed[key] = value;
        this.props.onChange(embed);
    };

    renderFields = () => {
        var fields = [];


        this.props.embed.fields.forEach((field, index) => {

            fields.push(
                <div className='border border-1-[#adb5bd] grid gap-y-2 border-[#adb5bd]  rounded-sm p-3 relative'>

                    <div className='top-2 right-2 absolute flex hover:cursor-pointer text-white opacity-80 hover:text-red hover:opacity-70'>
                        <TrashIcon className='h-5' onClick={() => {
                            var embed = { ...this.props.embed };
                            embed.fields.splice(index, 1);
                            this.props.onChange(embed);
                        }} />
                        <ClipboardDocumentIcon className='h-5 ml-2' onClick={() => {
                            if (this.props.embed.fields.length >= 25) return;
                            var newField = {
                                name: field.name,
                                value: field.value,
                            };
                            var embed = { ...this.props.embed };
                            embed.fields.push(newField);
                            this.props.onChange(embed);

                        }} />
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-md mb-2 font-bold leading-6 text-white">
                            Field {index + 1}<span className='muted-text opacity-50'> - {field.name}</span>
                        </label>
                    </div>
                    <div>
                        <ToolInput maxLength={256} bg="bg-darkGray" label="Name" value={field.name} onChange={(value) => {
                            var field = { ...this.props.embed.fields[index] };
                            field.name = value;
                            var embed = { ...this.props.embed };
                            embed.fields[index] = field;
                            this.props.onChange(embed);
                        }}></ToolInput>

                    </div>

                    <div>
                        <ToolTextArea maxLength={1024} bg="bg-darkGray" label="Value" value={field.value} onChange={(value) => {
                            var field = { ...this.props.embed.fields[index] };
                            field.value = value;
                            var embed = { ...this.props.embed };
                            embed.fields[index] = field;
                            this.props.onChange(embed);
                        }}></ToolTextArea>
                    </div>

                    <div className='flex justify-end mt-2'>
                        <span className='font-bold text-white mr-2'>Inline</span>
                        <Switch
                            checked={field.inline}
                            onChange={() => {
                                var field = { ...this.props.embed.fields[index] };
                                field.inline = !field.inline;
                                var embed = { ...this.props.embed };
                                embed.fields[index] = field;
                                this.props.onChange(embed);
                            }}
                            className={classNames(
                                field.inline ? 'bg-red' : 'bg-gray-200',
                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red focus:ring-offset-2'
                            )}
                        >
                            <span className="sr-only">Inline</span>
                            <span
                                aria-hidden="true"
                                className={classNames(
                                    field.inline ? 'translate-x-5' : 'translate-x-0',
                                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                )}
                            />
                        </Switch>
                    </div>

                </div>
            );
        });


        return fields;
    };

    renderError(errorType) {
        if (this.state.errors[errorType].error) {
            return (
                <div className='text-red mt-2 font-bold flex items-center'>
                    <ExclamationCircleIcon className="h-5 mr-1 text-red"></ExclamationCircleIcon><span> {this.state.errors[errorType].message}</span>
                </div>
            );
        }
    }
    render() {
        return (
            <div className='p-2'>
                <div style={{
                    borderLeftColor: this.props.embed.color ? this.props.embed.color : "#000000"
                }} className='bg-lightGray border-l-4 border-l-red px-3 py-3 rounded-sm text-white relative'>

                    <div className='flex align-center hover:cursor-pointer' onClick={() => {
                        this.props.openClose();
                    }}>
                        {this.props.open ? <div><ChevronDownIcon className='h-5'></ChevronDownIcon></div> : <div><ChevronRightIcon className='h-5'></ChevronRightIcon></div>}
                        <h4 className='font-bold'>Embed {this.props.index + 1} - <span className='muted-text'>{this.props.embed.title || "Embed"}</span></h4>
                        {!this.state.valid ? <div><ExclamationCircleIcon className="h-5 text-red"></ExclamationCircleIcon></div> : null}
                        <div className='ml-auto hover:cursor-pointer text-white opacity-80 hover:text-red hover:opacity-70'>

                            <div className='flex '>
                                <ClipboardDocumentIcon className='h-5 mr-2 hover:text-red hover:opacity-70' onClick={() => {
                                    // 
                                    this.props.copyEmbed();

                                }} />
                                <TrashIcon className=' h-5 hover:text-red hover:opacity-70' onClick={() => {
                                    this.props.delete();
                                }} />
                            </div>
                        </div>
                    </div>


                    <div className={`grid gap-y-2 mt-3 ${this.props.open ? "show" : "hidden"}`}>

                        {/* Author */}
                        <div>
                            <div className='mb-2'>
                                <ToolInput maxLength={256} bg="bg-darkGray" label="Author" value={this.props.embed.author?.name || ""} onChange={(value) => {
                                    var author = { ...this.props.embed.author };
                                    if (!author) author = {
                                        name: "",
                                        url: "",
                                        icon_url: ""
                                    };
                                    author.name = value;
                                    console.log(author, 'AUTHOR');
                                    this.updateData(author, "author");
                                }}></ToolInput>
                            </div>

                            <div className='grid grid-cols-2 gap-x-3'>
                                <div>
                                    <ToolInput bg="bg-darkGray" label="Author URL" value={this.props.embed?.author?.url} onChange={(value) => {
                                        var author = { ...this.props.embed.author };
                                        if (!author) author = {
                                            name: "",
                                            url: "",
                                            icon_url: ""
                                        };
                                        author.url = value;
                                        this.updateData(author, "author");
                                    }} ></ToolInput>

                                    {this.renderError("authorUrl")}
                                </div>

                                <div>
                                    <ToolInput bg="bg-darkGray" label="Author Icon URL" value={this.props.embed?.author?.icon_url} onChange={(value) => {
                                        var author = { ...this.props.embed.author };
                                        if (!author) author = {
                                            name: "",
                                            url: "",
                                            icon_url: ""
                                        };
                                        author.icon_url = value;
                                        this.updateData(author, "author");
                                    }}></ToolInput>

                                    {this.renderError("authorIconUrl")}
                                </div>

                            </div>
                        </div>

                        <div className='border-t-[1px] border-[#adb5bd] mb-2 mt-3'></div>

                        {/* Title + Description */}
                        <div>
                            <div className='mb-2'>
                                <ToolInput maxLength={256} value={this.props.embed.title || ""} bg="bg-darkGray" label="Title" onChange={(value) => {
                                    this.updateData(value, "title");
                                }}></ToolInput>
                            </div>

                            <div>
                                <ToolTextArea maxLength={4096} value={this.props.embed.description || ""} bg="bg-darkGray" label="Description" onChange={(value) => {
                                    this.updateData(value, "description");
                                }}></ToolTextArea>

                                {this.renderError("descriptionRequired")}
                            </div>

                            <div className='grid grid-cols-10 mt-2 gap-x-3'>
                                <div className='col-span-5 md:col-span-9'>
                                    <ToolInput bg="bg-darkGray" label="URL" onChange={(value) => {
                                        this.updateData(value, "url");
                                    }}></ToolInput>

                                    {this.renderError("url")}
                                </div>

                                <div className='col-span-5 md:col-span-1'>
                                    <div className='flex relative flex-col' ref={this.setWrapperRef} >
                                        {
                                            this.state.colorOpen ? <div className='absolute top-[72px]'>
                                                <ChromePicker onChange={(value) => {
                                                    console.log(value);
                                                    this.updateData(value.hex, "color");
                                                }} color={this.props.embed.color ? this.props.embed.color : "#000000"} />
                                            </div> : null
                                        }
                                        {/* <div className=''>
                                            <ToolInput bg="bg-darkGray" label="Color" onChange={(value) => {
                                                this.updateData(value, "color");
                                            }}></ToolInput>
                                        </div> */}
                                        <label htmlFor="email" className="block text-md font-bold leading-6 text-white">
                                            Color
                                        </label>

                                        <div onClick={() => {
                                            this.setState({ colorOpen: !this.state.colorOpen });
                                        }} style={{
                                            background: this.props.embed.color ? this.props.embed.color : "#000000"
                                        }} className={`h-[38px] rounded-sm w-full mt-2  hover:cursor-pointer `}>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='border-t-[1px] border-[#adb5bd] mb-2 mt-3'></div>

                        {/* Images */}
                        <div className='grid grid-cols-2 gap-x-3'>
                            <div className='mb-2'>
                                <ToolInput bg="bg-darkGray" label="Image URL" onChange={(value) => {
                                    var image = { ...this.props.embed.image };
                                    if (!image) image = {
                                        url: "",
                                    };
                                    image.url = value;
                                    this.updateData(image, "image");
                                }}></ToolInput>

                                {this.renderError("imageUrl")}
                            </div>

                            <div>
                                <ToolInput bg="bg-darkGray" label="Thumbnail URL" onChange={(value) => {
                                    var thumbnail = { ...this.props.embed.thumbnail };
                                    if (!thumbnail) thumbnail = {
                                        url: "",
                                    };
                                    thumbnail.url = value;
                                    this.updateData(thumbnail, "thumbnail");
                                }}></ToolInput>

                                {this.renderError("thumbnailUrl")}

                            </div>

                        </div>

                        <div className='border-t-[1px] border-[#adb5bd] mb-2 mt-3'></div>

                        {/* Footer */}

                        <div>
                            <div className='mb-2'>
                                <ToolTextArea maxLength={2048} value={this.props.embed.footer?.text || ""} bg="bg-darkGray" label="Footer" onChange={(value) => {
                                    var footer = { ...this.props.embed.footer };
                                    if (!footer) footer = {
                                        text: "",
                                        icon_url: "",
                                    };
                                    footer.text = value;
                                    this.updateData(footer, "footer");
                                }}></ToolTextArea>
                            </div>

                            <div className='grid grid-cols-1 gap-y-2 md:grid-cols-10 gap-x-3'>
                                <div className='md:col-span-7'>
                                    <ToolInput bg="bg-darkGray" label="Footer Icon URL" onChange={(value) => {
                                        var footer = { ...this.props.embed.footer };
                                        if (!footer) footer = {
                                            text: "",
                                            icon_url: "",
                                        };
                                        footer.icon_url = value;
                                        this.updateData(footer, "footer");
                                    }}></ToolInput>

                                    {this.renderError("footerIconUrl")}
                                </div>
                                <div className="flex flex-col content-center text-xl md:col-span-3">
                                    <label htmlFor="email" className="block text-[16px]  font-bold leading-6 text-white">
                                        Timestamp
                                    </label>
                                    <div>
                                        <DateTimePicker
                                            onChange={(time) => {
                                                console.log(time);
                                                if (!time) time = "";
                                                this.updateData(`${time}`, "timestamp");
                                                // this.fetchTimeStamp(time);
                                            }}
                                            disableClock={true}
                                            calendarClassName={"bg-darkGray text-white"}
                                            className={"bg-darkGray text-white mt-2 h-[40px] border-none w-full"}
                                            value={this.props.embed.timestamp ? this.props.embed.timestamp : ""}
                                        />
                                    </div>
                                </div>
                                {/* <ToolInput bg="bg-darkGray" label="Timestamp"></ToolInput> */}

                            </div>
                        </div>

                        <div className='border-t-[1px] border-[#adb5bd] mb-2 mt-3'></div>
                        {/* Fields */}

                        <div>
                            <label htmlFor="email" className="block text-md mb-2 font-bold leading-6 text-white">
                                Fields <span className='muted-text opacity-50'>{this.props.embed.fields.length}/25</span>
                            </label>


                            <div className='grid gap-y-4'>

                                {this.renderFields()}

                            </div>

                            <div className='mt-3'>
                                <button className='btn btn-red mr-2 px-1 py-1 font-bold' onClick={() => {
                                    if (this.props.embed.fields.length >= 25) return;
                                    var embed = { ...this.props.embed };
                                    embed.fields.push({
                                        name: "",
                                        value: "",
                                        inline: false
                                    });
                                    this.props.onChange(embed);
                                }}>Add Field</button>
                                <button className='btn btn-gray px-1 py-1 font-bold'>Clear Fields</button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default DiscordEmbed;;