import React, { Component } from 'react'
import { connect } from 'react-redux'

export class BlogRedirect extends Component {

    componentDidMount() {

        window.location.replace(`https://botghost.com/content/${this.props.match.params.id}`);
    }
    render() {
        return (
            <div>
                
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default BlogRedirect
