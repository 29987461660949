import React, { Component } from 'react'
import { connect } from 'react-redux'

export class Title extends Component {
    render() {
        return (
            <div className="title-container">
            <div>
                <h1>{this.props.header}</h1>
                <p>{this.props.description}</p>
            </div>  
        </div> 
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(Title)
